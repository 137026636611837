import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { getRandomInt, nullOrUndefined } from '../Utils/Common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    image: {},
  })
);

export function MiniBanner() {
  const classes = useStyles();
  const layout = useLayout();
  const validMiniBaners =
    layout.homeConfig.miniBanners?.filter(
      (s) =>
        (nullOrUndefined(s.validFrom) ||
          new Date(s.validFrom || '').toDateString() === new Date().toDateString() ||
          new Date(s.validFrom || '').toJSON() < new Date().toJSON()) &&
        (nullOrUndefined(s.validTo) ||
          new Date(s.validTo || '').toDateString() === new Date().toDateString() ||
          new Date(s.validTo || '').toJSON() > new Date().toJSON())
    ) || [];
  const banner = validMiniBaners.length === 0 ? undefined : validMiniBaners[getRandomInt(0, validMiniBaners.length)];

  return (
    (banner && (
      <div className={classes.root} onClick={banner.link ? () => window.open(banner.link, '_self') : undefined}>
        <img className={classes.image} src={banner.file} alt="miniBanner" />
      </div>
    )) || <></>
  );
}
