import React, { useEffect, useState } from 'react';
import { Container, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import EText from '../Components/EText';
import DeliveryLeafletOrderPanel from '../Components/DeliveryLeafletOrderPanel';
import ProductsPreviewPanel from '../Components/ProductsPreviewPanel';
import SubcategoryCard from '../Components/SubcategoryCard';
import { useLayout } from '../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../Utils/ApiService';
import { ITreeCategory } from '../Types/base';
import { loadCategories } from '../Utils/CategoriesService';
import RollingImg from '../Components/RollingImg';
import DailySpecial from '../Components/DailySpecial';
import { HeurekaInlineBadgeInitScript } from '../Components/HeurekaInlineBadge';
import HeurekaLeftBadge from '../Components/HeurekaLeftBadge';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topProductsContainer: {
      // backgroundColor: "#F6F8FB",
    },
  })
);

export default function Home() {
  const classes = useStyles();
  const layout = useLayout();
  const [topProducts, setTopProducts] = useState<IProduct[]>([]);
  const [categories, setCategories] = useState<ITreeCategory[]>([]);

  const validBanners =
    layout.homeConfig.banners
      ?.filter(
        (b) =>
          (b.validFrom === undefined ||
            b.validFrom === null ||
            new Date(b.validFrom).getDate() <= new Date().getDate()) &&
          (b.validTo === undefined || b.validTo === null || new Date(b.validTo).getDate() >= new Date().getDate())
      )
      .map((b) => {
        return { src: b.file, link: b.link, name: b.name };
      }) || [];

  useEffect(() => {
    if (
      layout.homeConfig.valid &&
      [
        ...new Set(
          layout.homeConfig.productSeries
            .filter(
              (ps) =>
                (ps.validFrom === undefined || new Date(ps.validFrom).getTime() < new Date().getTime()) &&
                (ps.validTo === undefined || new Date(ps.validTo).getTime() > new Date().getTime())
            )
            .flatMap((s) => s.products)
        ),
      ].length > 0
    ) {
      catalogService.get(
        'products/noauth/list',
        {
          ids: [...new Set(layout.homeConfig.productSeries.flatMap((s) => s.products))],
          mapped: true,
        },
        {
          success: setTopProducts,
          error: () => layout.error('Při načítání top produktů došlo k chybě'),
        }
      );
    }
    // eslint-disable-next-line
  }, [layout.homeConfig]);

  useEffect(() => {
    loadCategories(setCategories);
  }, []);

  useEffect(() => {
    layout.setSuklVerifyLogoVisible(true);
    layout.setVeterinaryLogoVisible(true);
  }, [layout]);

  return (
    <>
      <HeurekaLeftBadge />
      <HeurekaInlineBadgeInitScript />
      <Container maxWidth="lg">
        <RollingImg images={validBanners} interval={6000} />
        <br />
        <br />
        <br />
        <br />
        <DailySpecial specials={layout.homeConfig.todaysSpecial} />
      </Container>
      {layout.homeConfig.productSeries
        .filter(
          (ps) =>
            (ps.validFrom === undefined || new Date(ps.validFrom).getTime() < new Date().getTime()) &&
            (ps.validTo === undefined || new Date(ps.validTo).getTime() > new Date().getTime())
        )
        .map((s, i) => (
          <div className={classes.topProductsContainer} key={i}>
            <Container maxWidth="lg">
              {s.products.length > 0 && (
                <>
                  <br />
                  <EText variant="h4" align="center">
                    {s.name}
                  </EText>
                  <br />
                  <ProductsPreviewPanel products={topProducts.filter((tp) => s.products.includes(tp.id))} />
                </>
              )}
            </Container>
          </div>
        ))}
      <Container maxWidth="lg">
        <br />
        <br />
        <DeliveryLeafletOrderPanel />
        <br />
        <br />
        <EText variant="h6" align="center">
          Kategorie produktů
        </EText>

        <br />
        <Grid container>
          {categories?.map((c) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={c.id}>
              <SubcategoryCard category={c} />
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
      </Container>
    </>
  );
}
