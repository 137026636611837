import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import HeurekaReviewCard, { reviewProsDefined } from './HeurekaReviewCard';
import { ordersService } from '../Utils/ApiService';
import HeurekaReviewsSummary from './HeurekaReviewsSummary';
import IHeurekaReview from '../Types/heurekaReview';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexRoot: {
      padding: '20px 0',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    flexItem: {
      flex: '1 1 0px',
    },
  })
);

const REVIEWS_TO_SHOW = 3; //TODO: show based on map

const getReviewsToShow = (reviews: IHeurekaReview[]) => {
  const reviewsToShow: IHeurekaReview[] = [];
  for (const review of reviews) {
    if (review.summary || reviewProsDefined(review)) {
      reviewsToShow.push(review);
    }
    if (reviewsToShow.length >= REVIEWS_TO_SHOW) break;
  }
  return reviewsToShow;
};

const HeurekaReviewsBanner = () => {
  const classes = useStyles();

  const { data: reviews, error } = useQuery({
    queryKey: ['heurekaReviews'],
    queryFn: () => ordersService.get<IHeurekaReview[]>('heureka/reviews'),
    staleTime: 60 * 1000 * 9,
  });
  const reviewsToShow = getReviewsToShow(reviews ?? []);
  return reviews && !error ? (
    <>
      <div className={classes.flexRoot}>
        <div className={classes.flexItem}>
          <HeurekaReviewsSummary heurekaReviews={reviews} />
        </div>
        {reviewsToShow.map((review) => (
          <div key={review.id} className={classes.flexItem}>
            <HeurekaReviewCard review={review} />
          </div>
        ))}
      </div>
    </>
  ) : (
    <p></p> // error happened
  );
};

export default HeurekaReviewsBanner;
