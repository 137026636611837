export const secureId = (id: number) => {
  let prefix = 42132;
  let suffix = 92691;
  for (let i = 0; i < id; i++) {
    prefix +=
      ((i * prefix) % 100000) +
      ((i * i * 723) % 100000) +
      ((prefix * (100000 - prefix)) % 100000) +
      ((i * i * i * 931) % 100000);
    prefix %= 100000;
    suffix += ((suffix * 31) % 100000) + ((((i * i) % 100000) * i * 41) % 100000) + 942 + ((i * suffix) % 100000) + i;
    suffix %= 100000;
  }
  if (prefix < 10000) prefix += 10000 * (id % 10);
  return (prefix.toString() + '00000').substring(0, 5) + id.toString() + (suffix.toString() + '00000').substring(0, 5);
};
