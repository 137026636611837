import { Avatar, Button, Container, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import EText from './EText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    root: {
      // marginTop: theme.spacing(8),
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    banner: {
      width: '80%',
    },
  })
);

interface IProps {
  onAgeConfirm?: () => void;
  onAgeReject?: () => void;
}

export default function AgeVerification({ onAgeConfirm, onAgeReject }: IProps) {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        {/* <img src="/img/FinishedOrderImage.jpg" className={classes.banner} alt="orderReceivedBanner" /> */}
        <Container className={classes.root} maxWidth="sm">
          <Avatar className={classes.avatar}>18+</Avatar>
          <br />
          <EText variant="h5" align="center">
            Už jsem dospělý
          </EText>
          <br />
          <br />
          <EText align="center">
            Tato část webu není přístupná osobám mladším 18 let. Prosím potvrďte, že jste již dospělý.
          </EText>
          <br />
          <br />
          <Button variant="contained" color="secondary" onClick={onAgeConfirm}>
            Pokračovat
          </Button>
          <br />
          <Button variant="text" size="small" color="secondary" onClick={onAgeReject}>
            Vrátit se zpět
          </Button>
          <br />
          <br />
        </Container>
      </Container>
    </>
  );
}
