import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SHOW_HEUREKA_WIDGETS } from './HeurekaInlineBadge';

const HeurekaLeftBadge = () => {
  return (
    <>
      {SHOW_HEUREKA_WIDGETS ? (
        <Helmet>
          <script src="/js/heurekaLeftBadge.js" type="text/javascript"></script>
        </Helmet>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeurekaLeftBadge;
