import React, { useEffect, useState } from 'react';
import { Avatar, Button, Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EText from '../Components/EText';
import { AccessTime, Done, Error } from '@material-ui/icons';
import { ordersService } from '../Utils/ApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    root: {
      // marginTop: theme.spacing(8),
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

interface IProps {
  id: number;
  token: number;
}

export default function ConfirmPickupPlaceMail({ id, token }: IProps) {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    ordersService.post('/infrastructure/pickupPlace/confirm', { placeId: id, token: token }, null, {
      success: setConfirmed,
      error: () => setConfirmed(false),
    });
  }, [id, token]);

  const history = useHistory();

  return confirmed === undefined ? (
    <Container className={classes.root} maxWidth="sm">
      <Avatar className={classes.avatar}>
        <AccessTime />
      </Avatar>
      <br />
      <EText variant="h5" align="center">
        Čekání na ověření emailu
      </EText>
      <br />
      <br />
      <EText align="justify">Váš email se moentálně ověřuje, prosím chvíli počkejte.</EText>
      <br />
      <br />
      <br />
      <Button variant="contained" color="secondary" onClick={() => history.push(`/`)}>
        Zpět na hlavní stránku
      </Button>
      <br />
      <br />
    </Container>
  ) : confirmed === true ? (
    <Container className={classes.root} maxWidth="sm">
      <Avatar className={classes.avatar}>
        <Done />
      </Avatar>
      <br />
      <EText variant="h5" align="center">
        Váš email byl úspěšně ověřen
      </EText>
      <br />
      <br />
      <EText align="justify">
        Děkujeme za registraci lékárny a ověření emailu. Pro další postup Vás co nejdříve kontaktujeme.
      </EText>
      <br />
      <br />
      <br />

      <Button variant="contained" color="secondary" onClick={() => history.push(`/`)}>
        Zpět na hlavní stránku
      </Button>
      <br />
      <br />
    </Container>
  ) : (
    <Container className={classes.root} maxWidth="sm">
      <Avatar className={classes.avatar}>
        <Error />
      </Avatar>
      <br />
      <EText variant="h5" align="center">
        Váš email se nepodařilo ověřit
      </EText>
      <br />
      <br />
      <EText align="justify">
        Email se nepodařilo ověřit. Zkontrolujte si prosím, že url adresa se skutečně shoduje s adresou v emailu a
        případně nás prosím kontaktujte.
      </EText>
      <br />
      <br />
      <br />

      <Button variant="contained" color="secondary" onClick={() => history.push(`/`)}>
        Zpět na hlavní stránku
      </Button>
      <br />
      <br />
    </Container>
  );
}
