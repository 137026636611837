import React from 'react';
import { createStyles, makeStyles, Table, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import EText from '../../Components/EText';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productsPanel: {
      border: '1px solid #F6F8FB',
      borderRadius: 8,
      width: '100%',
    },
    productsHeader: {
      backgroundColor: '#F6F8FB',
    },
    productImage: {
      width: '10%',
      float: 'left',
      marginRight: 16,
    },
  })
);

interface IProps {
  presents: { present: IProduct; ammount: number }[];
}

export default function Gifts({ presents }: IProps) {
  const classes = useStyles();

  return (
    <>
      <br />
      <br />
      <br />
      <EText variant="h6">K tomu dostanete zdarma (1 Kč)</EText>
      <br />

      <div className={classes.productsPanel}>
        <Table>
          <TableHead>
            <TableRow className={classes.productsHeader}>
              <TableCell>
                <EText>Produkt</EText>
              </TableCell>
              <TableCell>
                <EText>Množství</EText>
              </TableCell>
            </TableRow>
            {presents.map(({ present, ammount }) => (
              <TableRow key={present.id}>
                <TableCell>
                  <img className={classes.productImage} src={(present.images || [''])[0]} alt="product preview" />
                  <EText color="secondary" variant="h6">
                    {present.title}
                  </EText>
                  <EText color="primary">{present.subtitle}</EText>
                  <EText color="primary" variant="caption">
                    {present?.shortDescription}
                  </EText>
                </TableCell>
                <TableCell align="center">
                  <EText color="primary" variant="h6">
                    {ammount}
                  </EText>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </div>
    </>
  );
}
