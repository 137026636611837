import React from 'react';
import Footer from './Footer';
import Header from './Header';

interface IProps {
  children?: React.ReactNode;
  suklVerifyLogoVisible: boolean;
  veterinaryVerifyLogoVisible: boolean;
  href: string;
}

export default function Layout({ children, suklVerifyLogoVisible, veterinaryVerifyLogoVisible, href }: IProps) {
  return (
    <>
      <Header hideHeader={href.includes('FinnishOrder')} />
      {children}
      <Footer
        hideFooter={href.includes('FinnishOrder')}
        suklVerifyLogoVisible={suklVerifyLogoVisible}
        veterinaryVerifyLogoVisible={veterinaryVerifyLogoVisible}
        href={href}
      />
    </>
  );
}
