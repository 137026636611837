import React, { useEffect, useState } from 'react';
import ContactInfoForm from '../Profile/ContactInfoForm';
import { IProfile } from '@magistrmartin/eshop-frontend-shared';
import { useAuth0 } from '@auth0/auth0-react';
import { profilesService } from '../../Utils/ApiService';
import { deliveryType, payment } from './FinnishOrder';
import PickupAlert from '../../Components/PickupAlert';

interface IProps {
  deliveryDetails: IProfile;
  setDeliveryDetails: (profile: IProfile) => void;
  setIsValid: (val: boolean) => void;
  deliveryType: deliveryType;
  paymentType: payment;
  contactPageVisited: boolean;
  setContactPageVisited: (visited: boolean) => void;
  differentDelivery: boolean;
  setDifferentDelivery: (val: boolean) => void;
}

export default function ContactInfo({
  deliveryDetails,
  setDeliveryDetails,
  deliveryType,
  paymentType,
  setIsValid,
  contactPageVisited,
  setContactPageVisited,
  differentDelivery,
  setDifferentDelivery,
}: IProps) {
  const [buyOnCompany, setBuyOnCompany] = useState(false);
  setContactPageVisited(true);

  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user && !contactPageVisited) {
      profilesService.get(
        '/',
        { userId: user.sub },
        {
          success: (p: IProfile) => {
            setDeliveryDetails(p);
            if (
              p.deliveryAddress?.name ||
              p.deliveryAddress?.surname ||
              p.deliveryAddress?.street ||
              p.deliveryAddress?.houseNum ||
              p.deliveryAddress?.zip ||
              p.deliveryAddress?.city
            )
              setDifferentDelivery(true);
            if (p.address?.company || p.vat || p.companyId) setBuyOnCompany(true);
          },
          error: console.log,
        }
      );
    }
    // eslint-disable-next-line
  }, [user, isAuthenticated]);

  return (
    <>
      <ContactInfoForm
        buyOnCompany={buyOnCompany}
        differentDelivery={differentDelivery}
        setDifferentDelivery={setDifferentDelivery}
        profile={deliveryDetails}
        setBuyOnCompany={setBuyOnCompany}
        setProfile={setDeliveryDetails}
        hideDeliveryAddress={deliveryType === 'personalPickup'}
        setIsValid={setIsValid}
      />
      <PickupAlert paymentType={paymentType} />
      <br />
      <br />
    </>
  );
}
