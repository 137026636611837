import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Container,
  Button,
} from '@material-ui/core';
import EText from '../Components/EText';
import { round, separateThousands, toCzechTime } from '../Utils/Common';
import { useEffect } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { imagesService, ordersService, catalogService } from '../Utils/ApiService';
import { useState } from 'react';
import { Order } from '../Types/order';
import { ShippingPaymentConfiguration, OrderState } from '@magistrmartin/eshop-frontend-shared';
import TotalSavingsPanel from './FinnishOrder/TotalSavingsPanel';
import { useHistory } from 'react-router-dom';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productsPanel: {
      border: '1px solid #F6F8FB',
      borderRadius: 8,
      width: '100%',
    },
    productsHeader: {
      backgroundColor: '#F6F8FB',
    },
    productImage: {
      width: '10%',
      float: 'left',
      marginRight: 16,
    },
  })
);

interface IProps {
  orderId: number;
}

export default function OrderPage({ orderId }: IProps) {
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );
  const [productsImages, setProductsImages] = useState<{
    [index: number]: string;
  }>({});
  const history = useHistory();

  const classes = useStyles();
  const layout = useLayout();

  useEffect(
    () => {
      imagesService.get(
        '/text',
        { file: 'shippingPayment.json', location: 'config' },
        {
          success: setDeliveryPaymentConfig,
          error: () => layout.error('Při načítání cen dopravy a platby došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      ordersService.get(
        '/noauth/getSecured',
        { securedId: orderId },
        {
          success: (order: Order) => {
            setOrder(order);
            catalogService.get(
              '/products/noauth/getImages',
              { ids: order.orderedProductsList?.map((p) => p.productId) },
              {
                success: setProductsImages,
                error: console.log,
              }
            );
          },
          error: () => layout.error('Při načítání objednávky došlo k chybě'),
        }
      );
    },
    //eslint-disable-next-line
    [orderId]
  );

  const cancelOrder = () =>
    ordersService.post('/changeStatus', { orderId: order?.id, newStatus: 'Canceled' }, null, {
      success: () => window.location.reload(),
      error: () => layout.error('Objednávku se nepodařilo stornovat'),
    });

  const setPaymentToPickup = () =>
    ordersService.post('/noauth/changePaymentToPickup', { orderId: order?.id }, null, {
      success: () => window.location.reload(),
      error: () => layout.error('Objednávku se nepodařilo stornovat'),
    });

  const displayDeliveryAddress =
    order &&
    order.deliveryInfo &&
    (order.deliveryInfo.delName ||
      order.deliveryInfo.delSurname ||
      order.deliveryInfo.delStreet ||
      order.deliveryInfo.delHouseNum ||
      order.deliveryInfo.delZip ||
      order.deliveryInfo.delCity);

  const buyOnCompany =
    order &&
    order.deliveryInfo &&
    (order.deliveryInfo.company || order.deliveryInfo.vat || order.deliveryInfo.companyId);

  return (
    <Container maxWidth="lg">
      {(order && (
        <>
        {order.comgateId && order.paymentType !== 'pickup' && order.paid !== true && order.status === 'Waiting' && (
          <>
            <Alert severity="warning" variant="filled">
              Objednávka čeká na zaplacení
            </Alert>
            <br />
            <span style={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://payments.comgate.cz/' + order.comgateId)}
              >
                Zaplatit objednávku
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  layout.confirm(
                    'Opravdu chcete změnit způsob platby na dobírku?',
                    'Může dojít ke zvýšení celkové ceny objednávky způsobené vyšší cenou zvoleného způsobu platby.',
                    setPaymentToPickup
                  )
                }
              >
                Změnit způsob platby na dobírku
              </Button>
              <Button color="secondary" onClick={cancelOrder} style={{ marginLeft: 'auto' }}>
                Stornovat objednávku
              </Button>
            </span>
            <br />
            <br />
          </>
        )}
          <EText variant="h6">Stav objednávky</EText>
          <br />
          <table style={{ fontFamily: 'roboto' }}>
            <tbody>
              <tr>
                <td>
                  <OrderStatusLabel orderStatus="Waiting" />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>{toCzechTime(new Date(order.createdDate || '')).toLocaleString('cs-CZ')}</td>
              </tr>
              {
                // @ts-ignore
                order.statusChangeHistoryList.map((h) => (
                  <tr key={h.id}>
                    <td>
                      <OrderStatusLabel orderStatus={h.newState as OrderState} />
                    </td>
                    <td>{toCzechTime(new Date(h.date || '')).toLocaleString('cs-CZ')}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <br />
          <EText variant="h6">Objednané produkty</EText>
          <br />
          <br />
          <div className={classes.productsPanel}>
            <Table>
              <TableHead>
                <TableRow className={classes.productsHeader}>
                  <TableCell>
                    <EText>Produkt</EText>
                  </TableCell>

                  <TableCell align="center">
                    <EText>Cena&nbsp;za&nbsp;kus</EText>
                  </TableCell>
                  <TableCell align="center">
                    <EText>Množství</EText>
                  </TableCell>
                  <TableCell align="center">
                    <EText>Celková&nbsp;cena</EText>
                  </TableCell>
                </TableRow>
                {order.orderedProductsList?.map((p) => (
                  <TableRow
                    key={p.productId}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/product/${p.productId}`)}
                  >
                    <TableCell>
                      <img className={classes.productImage} src={productsImages[p.productId]} alt="product preview" />
                      <EText color="secondary" variant="h6">
                        {p.productTitle}
                      </EText>
                      <EText color="primary">{p.productSubtitle}</EText>
                    </TableCell>
                    <TableCell align="center">
                      <EText color="primary" variant="h6">
                        {round((p.finalPrice || 0) / ((p.totalAmmount || 1) - (p.freeAmmount || 0) || 1), 2)} Kč
                      </EText>
                      {(p.freeAmmount || 0) > 0 && (
                        <EText color="secondary" variant="caption">
                          {p.freeAmmount}x Zdarma
                        </EText>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <EText>{p.totalAmmount}</EText>
                    </TableCell>
                    <TableCell align="center">
                      <EText color="primary" variant="h6">
                        {separateThousands(p.finalPrice)} Kč
                      </EText>
                    </TableCell>
                  </TableRow>
                ))}
              </TableHead>
            </Table>
          </div>
          {/* {discountsStatus.getPresents().length > 0 && <Gifts gifts={discountsStatus.getPresents()} />} */}
          <br />
          <br />
          <br />
          <Grid container>
            <Grid item xs={12} md={6}>
              <EText variant="h6">Doprava</EText>
              <EText>
                {
                  deliveryPaymentConfig?.shippingPrices[
                    //TODO: remove this ignore...
                    //@ts-ignore
                    order.deliveryInfo.deliveryType
                  ].label
                }{' '}
                {(order.deliveryInfo?.deliveryPrice || 0) > 0
                  ? `(${order.deliveryInfo?.deliveryPrice} Kč)`
                  : '(zdarma)'}
              </EText>
              {order.deliveryInfo?.deliveryType === 'packeta' && (
                <>
                  <br />
                  <EText>
                    Bliší informace o výdejním místě naleznete na odkaze:{' '}
                    <a href={order.deliveryInfo.packetaPlaceUrl as string} target="_blank" rel="noopener noreferrer">
                      {order.deliveryInfo.packetaPlaceUrl}
                    </a>
                  </EText>
                </>
              )}
              <br />
              <EText>Hmotnost: {order.mass} g</EText>
            </Grid>
            <Grid item xs={12} md={6}>
              <EText variant="h6">Typ platby</EText>
              <EText>
                {
                  //@ts-ignore
                  deliveryPaymentConfig?.paymentPrices[order.paymentType].label
                }{' '}
                {(order.paymentPrice || 0) > 0 ? `(${order.paymentPrice} Kč)` : '(zdarma)'}
              </EText>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <EText variant="h6">Dodací a fakturační údaje</EText>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={displayDeliveryAddress ? 6 : 12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Fakturační údaje</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Jméno</b>
                    </TableCell>
                    <TableCell>
                      {order.deliveryInfo?.name} {order.deliveryInfo?.surname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Adresa</b>
                    </TableCell>
                    <TableCell>
                      {order.deliveryInfo?.street} {order.deliveryInfo?.houseNum}, {order.deliveryInfo?.zip}{' '}
                      {order.deliveryInfo?.city}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>{order.deliveryInfo?.email}</TableCell>
                  </TableRow>
                  {order.deliveryInfo?.phone && (
                    <TableRow>
                      <TableCell>
                        <b>Telefon</b>
                      </TableCell>
                      <TableCell>{order.deliveryInfo.phone}</TableCell>
                    </TableRow>
                  )}
                  {order.deliveryInfo?.delPhone && (
                    <TableRow>
                      <TableCell>
                        <b>Telefon pro kurýra</b>
                      </TableCell>
                      <TableCell>{order.deliveryInfo.delPhone}</TableCell>
                    </TableRow>
                  )}
                  {order.deliveryInfo?.delComplany && (
                    <TableRow>
                      <TableCell>
                        <b>Firma</b>
                      </TableCell>
                      <TableCell>{order.deliveryInfo.delComplany}</TableCell>
                    </TableRow>
                  )}
                  {buyOnCompany && (
                    <>
                      <TableRow>
                        <TableCell>
                          <b>Firma</b>
                        </TableCell>
                        <TableCell>{order.deliveryInfo?.company}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>IČ</b>
                        </TableCell>
                        <TableCell>{order.deliveryInfo?.companyId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>DIČ</b>
                        </TableCell>
                        <TableCell>{order.deliveryInfo?.vat}</TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
            {displayDeliveryAddress && (
              <Grid item xs={12} md={6}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Dodací údaje</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Jméno</b>
                      </TableCell>
                      <TableCell>
                        {order.deliveryInfo?.delName} {order.deliveryInfo?.delSurname}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Adresa</b>
                      </TableCell>
                      <TableCell>
                        {order.deliveryInfo?.delStreet} {order.deliveryInfo?.delHouseNum}, {order.deliveryInfo?.delZip}{' '}
                        {order.deliveryInfo?.delCity}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
          <br />
          <br />
          <EText variant="h6" align="right">
            Celková cena s DPH&nbsp;&nbsp;&nbsp;&nbsp;
            {separateThousands(order.totalPrice)} Kč
          </EText>
          <br />
          <br />
          <TotalSavingsPanel expectedPrice={order.totalSavings || 0} actualPrice={0} />
          <br />
          <br />
        </>
      )) || <></>}
    </Container>
  );
}
