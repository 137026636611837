import React, { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IDailySpecial } from '../Types/base';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../Utils/ApiService';
import { getPrice } from '../Utils/Product';
import CartButton from './CartButton';
import EText from './EText';
import LabelsContainer from './LabelsContainer';
import useWindowDimensions from '../Hooks/GetWindowDimenstions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clickable: {
      cursor: 'pointer',
    },
    reccomendedPanel: {
      borderRadius: 8,
      marginBottom: 32,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      position: 'relative',
      cursor: 'pointer',
      textTransform: 'none',
    },
    reccomendedHead: {
      paddingTop: 8,
      paddingBottom: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme.palette.primary.main,
    },
    reccomendedBody: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    reccomendedImage: {
      width: '90%',
      left: '5%',
    },
    flex: {
      display: 'flex',
    },
    flexGrow: {
      flexGrow: 1,
    },
  })
);

interface IProps {
  specials: IDailySpecial[];
}

export default function DailySpecial({ specials }: IProps) {
  const classes = useStyles();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [, setTime] = useState(new Date());

  const history = useHistory();
  const special = specials.filter((s) => new Date(s.validAt).toDateString() === new Date().toDateString())[0];
  const followingSecials = specials
    .filter(
      (s) =>
        new Date(s.validAt).toDateString() !== new Date().toDateString() &&
        new Date(s.validAt).toJSON() > new Date().toJSON()
    )
    .slice(0, 3);

  const getSpecialById = (id: number) => followingSecials.filter((sp) => sp.productId === id)[0];
  const { width } = useWindowDimensions();

  const product = products.filter((p) => p.id === special.productId)[0];
  const followingProducts = products
    .filter((p) => p.id !== special.productId)
    .sort((a, b) =>
      new Date(getSpecialById(a.id).validAt).toJSON() < new Date(getSpecialById(b.id).validAt).toJSON()
        ? -1
        : new Date(getSpecialById(a.id).validAt).toJSON() === new Date(getSpecialById(b.id).validAt).toJSON()
          ? 0
          : 1
    );

  useEffect(() => {
    if (special === undefined) {
      setProducts([]);
    } else if (product?.id !== special.productId) {
      catalogService.get(
        'products/noauth/list',
        {
          ids: [special.productId, ...followingSecials.map((sp) => sp.productId)],
          mapped: true,
        },
        {
          success: (data: IProduct[]) => setProducts(data),
          error: console.log,
        }
      );
    }
    // eslint-disable-next-line
  }, [special, product]);

  useEffect(() => {
    if (special !== undefined) {
      const interval = setInterval(() => setTime(new Date()), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [special]);

  return product === undefined ? (
    <></>
  ) : (
    <>
      <div className={classes.reccomendedPanel} onClick={() => history.push('/product/' + product?.id)}>
        <div className={classes.reccomendedHead}>
          <EText variant="h6" color="contrast" align="center">
            Trhák dne
          </EText>
        </div>
        <div className={classes.reccomendedBody}>
          <Grid container>
            <Grid xs={12} sm={3} item>
              <LabelsContainer product={product} showBottomDescription={false}>
                <img
                  className={classes.reccomendedImage}
                  alt="product logo"
                  src={(product.images?.length || 0) > 0 ? (product?.images || [])[0] : '/img/noImage.webp'}
                />
              </LabelsContainer>
              <EText variant="caption">{product.characteristics}</EText>
            </Grid>
            <Grid xs={12} sm={9} item>
              <div className={classes.flex} style={{ flexDirection: width < 900 ? 'column' : 'row' }}>
                <div>
                  <EText color="secondary" variant="h4">
                    {product.title}
                  </EText>
                  <EText>{product.subtitle}</EText>
                  <EText>{product.shortDescription}</EText>
                  <br />
                  {(getPrice(product, specials) || 0) < (product.reccomendedPrice || 0) && (
                    <Typography
                      noWrap
                      display="inline"
                      style={{ textDecoration: 'line-through' }}
                      align="center"
                      color="secondary"
                      variant="h6"
                    >
                      {product.reccomendedPrice} Kč
                    </Typography>
                  )}
                  <EText variant="h4" color="primary">
                    {getPrice(product, specials)} Kč
                  </EText>
                  <EText color="secondary" variant="caption">
                    Platí pouze {new Date().toLocaleDateString('cs-CZ')} nebo do vyprodání zásob.
                  </EText>
                  {followingProducts.length > 0 && (
                    <EText color="secondary" variant={'h4'}>
                      {new Date(new Date().setHours(24, 0, 0, 0) - Date.now() - 3600000).toLocaleTimeString('cs-CZ')}
                    </EText>
                  )}
                </div>
                <div className={classes.flexGrow}>&nbsp;</div>
                <div style={{ maxWidth: width < 900 ? '100%' : '60%' }}>
                  {followingProducts.length > 0 && (
                    <EText color="secondary" variant="h5">
                      Můžete se těšit na
                    </EText>
                  )}
                  <div style={{ display: 'flex' }}>
                    {followingProducts.map((fp) => (
                      <div key={fp.id} style={{ maxWidth: '30%', textAlign: 'center' }}>
                        <img
                          className={classes.reccomendedImage}
                          alt="product logo"
                          src={(fp.images?.length || 0) > 0 ? (fp?.images || [])[0] : '/img/noImage.webp'}
                        />
                        <EText align="center" color="secondary">
                          <b>{fp.title}</b>
                        </EText>
                        <EText align="center">
                          Za {followingSecials.filter((sp) => sp.productId === fp.id)[0]?.specialPrice} Kč (
                          <span style={{ textDecoration: 'line-through' }}>{getPrice(fp, [])} Kč</span>)
                        </EText>
                        <EText align="center" color="secondary" variant="caption" style={{ width: '100%' }}>
                          Platí{' '}
                          {new Date(
                            followingSecials.filter((sp) => sp.productId === fp.id)[0]?.validAt
                          ).toLocaleDateString('cs-CZ')}
                          .
                        </EText>
                      </div>
                    ))}
                  </div>
                  {followingProducts.length === 0 && (
                    <EText color="secondary" variant={'h1'}>
                      {new Date(new Date().setHours(24, 0, 0, 0) - Date.now() - 3600000).toLocaleTimeString('cs-CZ')}
                    </EText>
                  )}
                </div>
              </div>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
          <CartButton fullWidth ammount={1} product={product} onClick={(e) => e.stopPropagation()} />
        </div>
      </div>
    </>
  );
}
