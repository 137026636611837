import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Container, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import CommonHeader from './CommonHeader';
import EText from '../../Components/EText';
import { IProfile } from '@magistrmartin/eshop-frontend-shared';
import { profilesService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import ContactInfoForm from './ContactInfoForm';
import DateFnsUtils from '@date-io/date-fns';
import csLocale from 'date-fns/locale/cs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ETextField from '../../Components/ETextField';
import Environment from '../../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      alignItems: 'center',
      height: 56,
    },
  })
);

export default function Profile() {
  const classes = useStyles();
  const { user, isAuthenticated } = useAuth0();
  const [agreeGDPR, setAgreeGDPR] = useState(false);
  const [buyOnCompany, setBuyOnCompany] = useState(false);
  const [differentDelivery, setDifferentDelivery] = useState(false);
  const [profile, setProfile] = useState<IProfile>({});
  const layout = useLayout();

  const saveProfile = () => {
    profilesService.post('/', {}, profile, {
      success: () => layout.success('Profilové údaje byly úspěšně změněny'),
      error: () => layout.error('Při změně profilových údajů došlo k chybě, zkuste to prosím později'),
    });
  };

  useEffect(() => {
    if (user) {
      profilesService.get(
        '/',
        { userId: user.sub, domain: Environment.domain },
        {
          success: (p: IProfile) => {
            setProfile(p);
            if (
              p.deliveryAddress?.name ||
              p.deliveryAddress?.surname ||
              p.deliveryAddress?.street ||
              p.deliveryAddress?.houseNum ||
              p.deliveryAddress?.zip ||
              p.deliveryAddress?.city
            )
              setDifferentDelivery(true);
            if (p.address?.company || p.vat || p.companyId) setBuyOnCompany(true);
          },
          error: (e) => layout.error('Při nahrávání profiu došlo k chybě: ' + e),
        }
      );
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      {isAuthenticated && (
        <Container maxWidth="lg">
          <CommonHeader selected="profile" />
          <br />
          <br />
          <br />
          <EText variant="h5" align="center">
            Upravit osobní údaje
          </EText>
          <br />
          <ContactInfoForm
            buyOnCompany={buyOnCompany}
            differentDelivery={differentDelivery}
            profile={profile}
            setBuyOnCompany={setBuyOnCompany}
            setDifferentDelivery={setDifferentDelivery}
            setProfile={setProfile}
          />
          <br />
          <br />
          <EText variant="h5">Data – připravujeme nové funkce</EText>
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={12}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  openTo="month"
                  views={['month', 'date']}
                  format="dd. MMMM"
                  margin="normal"
                  label="Svátek"
                  value={new Date((profile.nameday || new Date()).toString())}
                  onChange={(val) => val && setProfile({ ...profile, nameday: val })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <EText variant="caption">
                  Zaklikněte si v kalendáři datum svátku a získáte od nás k svátku přání a dárek
                </EText>
              </Grid>
              <Grid item md={6} sm={12}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  openTo="year"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="dd.MM.yyyy"
                  margin="normal"
                  inputVariant="outlined"
                  label="Narozeniny"
                  value={new Date((profile.birthday || new Date()).toString())}
                  onChange={(val) => val && setProfile({ ...profile, birthday: val })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <EText variant="caption">
                  Zaklikněte si v kalendáři datum narozenin a získáte od nás k narozeninám přání a dárek
                </EText>
              </Grid>
              <Grid item md={6} sm={12}>
                <ETextField
                  label="Věk"
                  value={(profile.age || 0).toString()}
                  onChange={(val) => setProfile({ ...profile, age: parseInt(val || '0') })}
                />
                <EText variant="caption">
                  V případě nevyplnění narozenin můžete zadat váš věk v letech, budeme vám doporučovat vhodnější
                  produkty s ohledem na váš věk
                </EText>
              </Grid>
              <Grid item md={6} sm={12}>
                <ETextField
                  label="Hmotnost"
                  value={(profile.mass || 0).toString()}
                  onChange={(val) => setProfile({ ...profile, mass: parseInt(val || '0') })}
                />
                <EText variant="caption">
                  Můžete zadat vaši hmotnost a budeme moci vám lépe poradit s dávkováním přípravků
                </EText>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <br />
          <div className={classes.verticalCenter}>
            <Checkbox checked={agreeGDPR} onChange={() => setAgreeGDPR(!agreeGDPR)} color="primary" />{' '}
            <EText inline>Souhlasím se zpracováním osobních údajů</EText>
          </div>
          <Button onClick={saveProfile} variant="contained" color="primary" fullWidth disabled={!agreeGDPR}>
            Uložit profil
          </Button>
          <br />
          <br />
        </Container>
      )}
    </>
  );
}
