import React, { useState } from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: { zIndex: 1 },
    button: {
      height: 56,
      width: 84,
      marginLeft: -84,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      fontFamily: 'Roboto',
      color: theme.palette.primary.contrastText,
      zIndex: 999,
    },
  })
);

interface IProps {
  btnLabel: string;
  label?: string;
  onClick: (value: string) => void;
}

export default function ETextfieldButton({ btnLabel, label, onClick }: IProps) {
  const classes = useStyles();
  const [val, setVal] = useState('');

  return (
    <>
      <TextField
        label={label}
        value={val}
        onChange={(e) => setVal(e.target.value)}
        variant="outlined"
        type="text"
        fullWidth
        className={classes.textField}
      />
      <button
        className={classes.button}
        onClick={() => {
          onClick(val);
          setVal('');
        }}
      >
        {btnLabel}
      </button>
    </>
  );
}
