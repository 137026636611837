import { FaMagnifyingGlass } from 'react-icons/fa6';

import { Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  productId: number;
  showWithText?: boolean;
}

const useStyles = (props: { showWithText: boolean }) =>
  makeStyles((theme: Theme) =>
    createStyles({
      leafButton: {
        boxSizing: 'border-box',
        borderRadius: props.showWithText ? '15px' : '100%',
        padding: props.showWithText ? '16px' : 'initial',
        width: props.showWithText ? '100%' : '40px',
        height: props.showWithText ? 'fit-content' : '40px',
        border: 'none',
        transition: 'all 0.2s ease',
        '&:hover': {
          //backgroundColor: 'rgba(0, 0, 0, 0.1)',
          filter: 'brightness(95%)',
          cursor: 'pointer',
          transform: 'translateY(-5px)',
        },
        color: 'black',
        backgroundColor: '#ffc812',
      },
      buttonText: {
        fontFamily: 'Helvetica',
        fontSize: '0.9rem',
        marginLeft: '10px',
      },
    })
  );

const buttonText = 'Porovnání cen produktů se stejnou účinnou látkou';

const Substancesbutton = ({ productId, showWithText = false }: IProps) => {
  const history = useHistory();
  const classes = useStyles({ showWithText })();

  return (
    <>
      <Tooltip title={showWithText ? '' : buttonText}>
        <button
          className={classes.leafButton}
          onClick={(e) => {
            e.stopPropagation();
            history.push('/substances/' + productId);
          }}
        >
          <FaMagnifyingGlass />

          {showWithText && <span className={classes.buttonText}>{buttonText}</span>}
        </button>
      </Tooltip>
    </>
  );
};

export default Substancesbutton;
