import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { shared } from 'use-broadcast-ts';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { IDailySpecial } from '../Types/base';
import { produce, Draft } from 'immer';
import { reducers } from '../Utils/Reducers';
import { getTotalProductPrice } from '../Utils/Product';
import { DiscountsStatus } from '../Utils/Discounts';
import CartItem from '../Types/cartItem';

export interface CartState {
  cartContent: { [index: string]: CartItem };
  expireTime: Date | undefined;
  setStoreExpireAndClearIfOld: () => void;
  getProducts: () => CartItem[];
  getContentSize: () => number;
  addProduct: (product: IProduct, ammount?: number) => void;
  getCartPrice: (todaySpecials: IDailySpecial[]) => number;
  removeProduct: (product: IProduct) => void;
  editAmmount: (product: IProduct, newAmmount: number) => void;
  clear: () => void;
}

type ImmerCart = Draft<{
  // TODO: our project is using old TS compiler and it cannot recognize this type (even when VS code can...). We can remove it with newer Typescript
  [index: string]: CartItem;
}>;

const useCart = create<CartState>()(
  shared(
    persist(
      (set, get) => {
        return {
          cartContent: {},
          expireTime: undefined,
          setStoreExpireAndClearIfOld: () => {
            if (get().getContentSize() === 0) {
              set((state: CartState) => ({ expireTime: undefined }));
              return;
            }
            const currExpireTime = get().expireTime;
            if (!currExpireTime) {
              let updatedExpireTime = new Date();
              updatedExpireTime.setDate(updatedExpireTime.getDate() + 2);
              updatedExpireTime.setHours(2, 0, 0, 0);
              set((state: CartState) => ({ expireTime: updatedExpireTime }));
            } else {
              if (new Date().getTime() > new Date(currExpireTime).getTime()) {
                get().clear();
                set((state: CartState) => ({ expireTime: undefined }));
              }
            }
          },
          getProducts: () => Object.values(get().cartContent),
          getContentSize: () =>
            Object.keys(get().cartContent)
              .map((k) => get().cartContent[k].amount)
              .reduce(reducers.sum, 0),
          addProduct: (product, amount) => {
            if (amount === undefined) amount = 1;

            let updatedCartContent = produce(get().cartContent, (cartDraft: ImmerCart) => {
              if (!(product.id.toString() in cartDraft))
                cartDraft[product.id.toString()] = {
                  amount: 0,
                  product: product,
                };
              cartDraft[product.id.toString()].amount += amount!;
              if (
                (product.maxCartAmount || 0) > 0 &&
                cartDraft[product.id.toString()].amount > (product.maxCartAmount || 0)
              )
                cartDraft[product.id.toString()].amount = product.maxCartAmount || 0;
            });
            set((state: CartState) => ({
              cartContent: updatedCartContent,
            }));
            get().setStoreExpireAndClearIfOld();
          },
          getCartPrice: (todaySpecials) => {
            const discountsStatus = new DiscountsStatus(get().getProducts(), [], false, todaySpecials);
            return (
              get()
                .getProducts()
                .reduce(
                  (acc: number, val: CartItem) =>
                    acc + getTotalProductPrice(val.amount, val.product, discountsStatus, todaySpecials),
                  0
                ) +
              discountsStatus.getSymbolicPriceForFreeProducts() -
              discountsStatus.getAdditionalTotalDiscount()
            );
          },
          removeProduct: (product) => {
            let updatedCartContent = produce(get().cartContent, (cartDraft: ImmerCart) => {
              if (product.id.toString() in cartDraft) {
                delete cartDraft[product.id.toString()];
              }
            });
            set((state: CartState) => ({
              cartContent: updatedCartContent,
            }));
          },
          editAmmount: (product, newAmount) => {
            let updatedCartContent = produce(get().cartContent, (cartDraft: ImmerCart) => {
              if (newAmount === 0) get().removeProduct(product);
              else {
                if (product.id.toString() in cartDraft) {
                  cartDraft[product.id.toString()].amount = newAmount;
                  if (
                    (product.maxCartAmount || 0) > 0 &&
                    cartDraft[product.id.toString()].amount > (product.maxCartAmount || 0)
                  )
                    cartDraft[product.id.toString()].amount = product.maxCartAmount || 0;
                } else get().addProduct(product, newAmount);
              }
            });
            set((state: CartState) => ({
              cartContent: updatedCartContent,
            }));
          },
          clear: () => set({ cartContent: {}, expireTime: undefined }),
        };
      },
      { name: 'MagistrMartinCart' }
    )
  )
);

export default useCart;
