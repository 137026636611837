import React from 'react';
import { ILabel } from './LabelsContainer';
import { Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type LabelsSize = 'sm' | 'md';
interface IStyleProps {
  labelsSize: LabelsSize;
}
const useStyles = (props: IStyleProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      label: {
        display: 'inline',
        padding: '5px',
        fontFamily: 'Roboto',
        fontSize: props.labelsSize === 'sm' ? '0.8rem' : '1rem',
        color: 'white',
      },
      labelsStack: {
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'column',
      },
      labelIcon: {
        marginRight: '5px',
      },
    })
  );

interface IProps {
  labels: ILabel[];
  labelsSize?: LabelsSize;
}
const LabelsStack = ({ labels, labelsSize = 'md' }: IProps) => {
  const classes = useStyles({ labelsSize })();
  return (
    <>
      <span className={classes.labelsStack}>
        {labels.map((label) => (
          <Tooltip title={label.longDescription ?? ''} key={label.shortDescription}>
            <span
              style={{ backgroundColor: label.backgroundColor }}
              className={classes.label}
              key={label.shortDescription}
            >
              {label.icon && (
                <FontAwesomeIcon className={classes.labelIcon} icon={label.icon} key={label.longDescription} />
              )}
              {label.shortDescription}
            </span>
          </Tooltip>
        ))}
      </span>
    </>
  );
};

export default LabelsStack;
