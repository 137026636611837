import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ICategory, IProduct } from '@magistrmartin/eshop-frontend-shared';
import { ITreeCategory } from '../Types/base';
import { useHistory } from 'react-router-dom';
import { getCategoryById, getParentCategory, loadCategories } from '../Utils/CategoriesService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginBottom: 16,
      marginTop: 8,
      maxWidth: '100%',
      overflowX: 'auto',
    },
    pathItem: {
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    pathSeparator: {
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      marginRight: 4,
      marginLeft: 4,
    },
  })
);

interface IProps {
  currentCategory?: ICategory;
  currentProduct?: IProduct;
}

export default function HeadMap({ currentCategory, currentProduct }: IProps) {
  const classes = useStyles();
  const [categories, setCategories] = useState<ITreeCategory[]>([]);
  const history = useHistory();

  useEffect(() => {
    loadCategories(setCategories);
  }, []);

  useEffect(() => {
    currentCategory && sessionStorage.setItem('lastVisitedCategory', currentCategory?.id || '');
  }, [currentCategory]);

  const getCategoriesPath = (cat?: ICategory) => {
    if (cat === undefined) return [];
    const res = [];
    res.push(cat);
    for (
      let parent = getParentCategory(cat, categories);
      parent !== undefined;
      parent = getParentCategory(parent, categories)
    )
      res.push(parent);
    return res.reverse();
  };

  const isLastCategoryInProduct = () => {
    if (currentProduct === undefined) return false;
    const lastCategoryId = sessionStorage.getItem('lastVisitedCategory');
    if (lastCategoryId === null || lastCategoryId.length === 0) return false;
    return currentProduct.categories?.reduce((acc, cat) => acc || cat.startsWith(lastCategoryId), false) || false;
  };

  const productCategory =
    (currentProduct &&
      currentProduct.categories &&
      currentProduct.categories.length > 0 &&
      getCategoryById(
        isLastCategoryInProduct()
          ? (sessionStorage.getItem('lastVisitedCategory') as string)
          : currentProduct.categories[0],
        categories
      )) ||
    undefined;

  const catsPath = getCategoriesPath(currentCategory || productCategory);

  return (
    (catsPath.length > 0 && (
      <div className={classes.root}>
        <div className={classes.pathItem} onClick={() => history.push('/')}>
          Domů
        </div>
        {catsPath.map((c) => (
          <React.Fragment key={c.id}>
            <div className={classes.pathSeparator} onClick={() => history.push('/')}>
              &rarr;
            </div>
            <div className={classes.pathItem} onClick={() => history.push(`/offer/category/${c.id}`)}>
              {c.description}
            </div>
          </React.Fragment>
        ))}
        {currentProduct && (
          <>
            <div className={classes.pathSeparator} onClick={() => history.push('/')}>
              &rarr;
            </div>
            <div className={classes.pathItem}>{currentProduct.title}</div>
          </>
        )}
      </div>
    )) || <></>
  );
}
