import React, { useEffect, useState } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import { apiUrl } from '../Utils/ApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    frame: {
      border: 'none',
      width: '100%',
    },
  })
);

interface IProps {
  id: number;
}

export default function StaticSite({ id }: IProps) {
  const classes = useStyles();
  const [content, setContent] = useState('');
  const [frameHeight, setFrameHeight] = useState('0px');

  const iframeReady = () => {
    let iframe = document.getElementById('iframeTarget') as HTMLIFrameElement;
    //@ts-ignore
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      setFrameHeight((iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight) + ' px');
  };

  useEffect(() => {
    fetch(`${apiUrl}structure/sites/?id=${id}`).then((res) => res.text().then(setContent));
  }, [id]);

  return (
    <Container maxWidth="lg">
      <iframe
        id="iframeTarget"
        title="content"
        srcDoc={content}
        className={classes.frame}
        onLoad={iframeReady}
        height={frameHeight}
      />
    </Container>
  );
}
