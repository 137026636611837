import React from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Theme, 
  createStyles, 
  makeStyles, 
  IconButton, 
  } from '@material-ui/core';
  import CloseSharp from '@material-ui/icons/CloseSharp';
import { payment } from '../Pages/FinnishOrder/FinnishOrder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      h1: {
        textDecoration: 'bold',
      },
      text:{
        color: 'black',
        textAlign: 'justify',
      },
      dialog: {
        borderRadius: '30px',
      }
    })
  );

interface IProps {
    paymentType: payment;
}

export default function PickupAlert({
    paymentType,
  }: IProps) {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();

    const handleClose = () => {
      setOpen(false);
    };
    
    if (paymentType !== "pickup") {
        return null;
      }

    return (
      <React.Fragment>
        <Dialog PaperProps={{
          style: { borderRadius: 15 }
          }}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle className={classes.h1}>
            {"Informace o poplatku za nevyzvednutou zásilku"}
          </DialogTitle>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseSharp/>
        </IconButton>
          <DialogContent>
            <DialogContentText className={classes.text}>
            Vážení klienti, při nevyzvednutí zásilky z jakéhokoliv důvodu nám vznikají zbytečné náklady na balení a dopravu a to zásilky balíme s péčí. 
            Z hlediska občanského zákoníku odstavce §2118 si můžeme účtovat poplatek za vrácenou zásilku. 
            V takovém případě vystavíme fakturu na výše uvedené náklady v hodnotě 189 Kč s DPH. Vyzvedávejte si, prosím, Vaše zásilky. Děkujeme za pochopení. 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Beru na vědomí
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }