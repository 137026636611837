import React, { useState } from 'react';
import { createStyles, Grid, makeStyles, Theme, Slider, Chip, Button } from '@material-ui/core';
import EText from './EText';
import { IForm } from '../Types/base';
import { useEffect } from 'react';
import { catalogService } from '../Utils/ApiService';
import { qualityLabels } from '../Utils/QualityLabels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: 16,
      paddingLeft: 24,
      paddingRight: 24,
    },
    closed: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    formChip: {
      marginRight: 8,
    },
  })
);

interface IProps {
  categoryId: string;
  filterState: IFilterState;
  setFilterState: (newState: IFilterState) => void;
}

export interface IFilterState {
  set: boolean;
  ageLt: number;
  massLt: number;
  forms: number[];
  qualityLabels: string[];
}

export const initialFilterState: IFilterState = {
  set: false,
  ageLt: 0,
  massLt: 0,
  forms: [],
  qualityLabels: [],
};

const isFilterInitial = (filterToCompare: IFilterState) => {
  if (
    filterToCompare.ageLt === initialFilterState.ageLt &&
    filterToCompare.massLt === initialFilterState.massLt &&
    filterToCompare.forms.length === initialFilterState.forms.length &&
    filterToCompare.qualityLabels.length === initialFilterState.qualityLabels.length
  )
    return true;
  else return false;
};

export default function Filters({ filterState, setFilterState, categoryId }: IProps) {
  const classes = useStyles();
  const [forms, setForms] = useState<IForm[]>([]);
  const [hidden, setHidden] = useState(JSON.parse(localStorage.getItem('filtersHidden') || 'true'));

  // We want to rerender UI when user moves with slider, but we want to set filter state,
  // when was slider released (othervise there is a looot of HTTP request for each change
  const [massLtUI, setMassLtUI] = useState(initialFilterState.massLt);
  const [ageLtUI, setAgeLtUI] = useState(initialFilterState.ageLt);

  useEffect(() => {
    changeFiltersState({ ...filterState, forms: [] });
    catalogService.get(
      '/settings/noauth/simpleForms',
      {},
      {
        success: (forms: IForm[]) => setForms(forms.filter((f) => categoryId.startsWith(f.category))),
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, [categoryId]);

  const changeHidden = () => {
    localStorage.setItem('filtersHidden', JSON.stringify(!hidden));
    setHidden(!hidden);
  };

  const changeFiltersState = (newState: IFilterState) => {
    if (isFilterInitial(newState)) {
      newState.set = false;
    } else {
      newState.set = true;
    }
    setFilterState(newState);
  };

  return hidden ? (
    <div className={`${classes.root} ${classes.closed}`} style={{ cursor: 'pointer' }} onClick={changeHidden}>
      <EText onClick={() => {}} color="contrast" variant="h6" align="center">
        Přesnější vyhledávání podle filtrů
      </EText>
      <br />
      <EText align="center" color="contrast">
        Chcete znát produkty bez umělých sladidel, bez cukru nebo které mohou těhotné? Umíme vyhledávat i podle věku.
        Klikněte sem a hledejte produkty podle určitých parametrů.
      </EText>
    </div>
  ) : (
    <div className={classes.root}>
      <EText variant="h6" align="center">
        Přesnější vyhledávání podle filtrů
      </EText>
      <br />
      <EText variant="h6" color="primary">
        Lékové formy – klikněte si na bubliny
      </EText>
      <br />
      {forms.map((form) => (
        <Chip
          key={form.id}
          label={form.name}
          color={filterState.forms.includes(form.id || -1) ? 'primary' : 'default'}
          className={classes.formChip}
          onClick={() =>
            filterState.forms.includes(form.id || -1)
              ? changeFiltersState({
                  ...filterState,
                  forms: filterState.forms.filter((i) => i !== form.id),
                })
              : changeFiltersState({
                  ...filterState,
                  forms: [...filterState.forms, form.id || -1],
                })
          }
        />
      ))}
      <br />
      <br />
      <EText variant="h6" color="primary">
        Štítky kvality – klikněte si na bubliny
      </EText>
      <br />
      {qualityLabels.map((lbl) => (
        <Chip
          label={lbl.label}
          key={lbl.label}
          color={filterState.qualityLabels.includes(lbl.name) ? 'primary' : 'default'}
          className={classes.formChip}
          onClick={() =>
            filterState.qualityLabels.includes(lbl.name)
              ? changeFiltersState({
                  ...filterState,
                  qualityLabels: filterState.qualityLabels.filter((i) => i !== lbl.name),
                })
              : changeFiltersState({
                  ...filterState,
                  qualityLabels: [...filterState.qualityLabels, lbl.name],
                })
          }
        />
      ))}
      <br />
      <br />
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <br />
          <EText variant="h6" align="center">
            Věková osa
          </EText>
          <EText align="center">Posunutím tečky zadejte věk pacienta, pro kterého je produkt určen</EText>
          <br />
          <Slider
            color="secondary"
            value={ageLtUI}
            onChange={(_, val) => setAgeLtUI(val as number)}
            onChangeCommitted={(_, val) =>
              changeFiltersState({
                ...filterState,
                ageLt: val as number,
              })
            }
            min={0}
            max={18}
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0 let' },
              { value: 18, label: '18 let' },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <br />
          <EText variant="h6" align="center">
            Hmotnostní osa
          </EText>
          <EText align="center">Posunutím tečky zadejte hmotnost pacienta, pro kterého je produkt určen</EText>
          <br />
          <Slider
            color="secondary"
            min={0}
            max={100}
            value={massLtUI}
            onChange={(_, val) => setMassLtUI(val as number)}
            onChangeCommitted={(_, val) =>
              changeFiltersState({
                ...filterState,
                massLt: val as number,
              })
            }
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0 kg' },
              { value: 100, label: '100 kg' },
            ]}
          />
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item md={9}>
            <EText variant="caption" color="primary">
              &nbsp;&nbsp;&nbsp;&nbsp;Filtr zobrazí jen produkty, které splňují daná kritéria. Produkty, kde daný údaj
              není zveřejněn, nebudou zobrazeny.
            </EText>
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={changeHidden}>
              Skrýt filtry
            </Button>
            <Button color="secondary" onClick={() => changeFiltersState(initialFilterState)}>
              Smazat filtry
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </Grid>
    </div>
  );
}
