import React from 'react';
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import EText from '../../Components/EText';
import { getPrice } from '../../Utils/Product';
import { DiscountsStatus } from '../../Utils/Discounts';
import { getTotalProductPrice } from '../../Utils/Product';
import Gifts from './Gifts';
import ETextfieldButton from '../../Components/ETextfieldButton';
import ECounter from '../../Components/ECounter';
import { convertGramsToKg, separateThousands } from '../../Utils/Common';
import { useHistory } from 'react-router-dom';
import CartItem from '../../Types/cartItem';
import useCart, { CartState } from '../../Stores/cartStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productsPanel: {
      border: '1px solid #F6F8FB',
      borderRadius: 8,
      width: '100%',
    },
    productsHeader: {
      backgroundColor: '#F6F8FB',
    },
    productImage: {
      width: '10%',
      float: 'left',
      marginRight: 16,
    },
    deleteProductBtn: {
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 26,
      paddingLeft: 12,
      paddingTop: 6,
      height: 32,
      width: 26,
    },
  })
);

interface IProps {
  products: CartItem[];
  discountsStatus: DiscountsStatus;
  totalPrice: number;
  presents: IProduct[];
  totalProductMass: number;
}

export default function ShoppingCart({ products, totalPrice, discountsStatus, presents, totalProductMass }: IProps) {
  const classes = useStyles();
  const layout = useLayout();
  const editProductAmount = useCart((state: CartState) => state.editAmmount);
  const removeProductFromCart = useCart((state: CartState) => state.removeProduct);
  const history = useHistory();

  return (
    <>
      <EText variant="h6">V nákupním košíku máte</EText>
      <br />
      <div className={classes.productsPanel}>
        <Table style={{ overflowX: 'auto', maxWidth: '100vw' }}>
          <TableHead>
            <TableRow className={classes.productsHeader}>
              <TableCell>
                <EText>Produkt</EText>
              </TableCell>
              <Hidden xsDown>
                <Hidden smDown>
                  <TableCell align="center">
                    <EText>Dostupnost</EText>
                  </TableCell>
                </Hidden>
                <TableCell align="center">
                  <EText>Cena&nbsp;za&nbsp;kus</EText>
                </TableCell>
              </Hidden>

              <TableCell align="center">
                <EText>Množství</EText>
              </TableCell>
              <Hidden xsDown>
                <TableCell align="center">
                  <EText>Celková&nbsp;cena</EText>
                </TableCell>
              </Hidden>
              <TableCell></TableCell>
            </TableRow>
            {products.map(({ product: p, amount }) => (
              <TableRow key={p.id}>
                <TableCell>
                  <Hidden smDown>
                    <img className={classes.productImage} src={(p.images || [''])[0]} alt="product preview" />
                  </Hidden>
                  <EText
                    color="secondary"
                    variant="h6"
                    onClick={() =>
                      history.push(
                        `/product/${p.title?.replace(/%/g, '')?.replace(/\//g, '')?.replace(/[ ]/g, '-')}-${p.subtitle
                          ?.replace(/%/g, '')
                          ?.replace(/\//g, '')
                          ?.replace(/[ ]/g, '-')}-${p.id}`
                      )
                    }
                  >
                    {p.title}
                  </EText>
                  <EText color="primary">{p.subtitle}</EText>
                  <Hidden smDown>
                    <EText color="primary" variant="caption">
                      {p.shortDescription}
                    </EText>
                  </Hidden>
                </TableCell>
                <Hidden xsDown>
                  <Hidden smDown>
                    <TableCell align="center">
                      {(p.stockAmmount || 0) > 0 ||
                      ((p.expiration || '') !== '' && new Date(p.expiration || '').getFullYear() > 2000) ? (
                        <EText color="secondary" variant="h6">
                          Skladem
                        </EText>
                      ) : (
                        <>
                          <EText color="secondary" variant="h6">
                            Není skladem
                          </EText>
                          <EText color="secondary" variant="caption">
                            Čeká&nbsp;na&nbsp;doskladnění
                          </EText>
                        </>
                      )}
                    </TableCell>
                  </Hidden>
                  <TableCell align="center">
                    <EText color="primary" variant="h6">
                      {separateThousands(getPrice(p, layout.homeConfig.todaysSpecial))} Kč
                    </EText>
                    {discountsStatus.getNumberOfFreeUnits(p) > 0 && (
                      <EText color="secondary" variant="caption">
                        {discountsStatus.getNumberOfFreeUnits(p)}
                        x&nbsp;Zdarma&nbsp;(1&nbsp;Kč)
                      </EText>
                    )}
                    {p.secondItemDiscount && p.secondItemDiscount.discount && amount > 1 && (
                      <EText color="secondary" variant="caption">
                        Každý 2. kus o {p.secondItemDiscount.discount.value}{' '}
                        {p.secondItemDiscount.discount.factor === 'absolute' ? ' Kč' : '%'} levněji
                      </EText>
                    )}
                    {discountsStatus.getNumberOfFreeUnits(p) === 0 &&
                      !(p.secondItemDiscount && p.secondItemDiscount.discount && amount > 1) &&
                      getPrice(p, layout.homeConfig.todaysSpecial) < (p.reccomendedPrice || 0) && (
                        <EText color="secondary" variant="caption">
                          Ušetříte{' '}
                          {separateThousands((p.reccomendedPrice || 0) - getPrice(p, layout.homeConfig.todaysSpecial))}{' '}
                          Kč
                        </EText>
                      )}
                  </TableCell>
                </Hidden>
                <TableCell align="center">
                  <ECounter
                    onChange={(newAmount) => {
                      editProductAmount(p, newAmount);
                    }}
                    value={amount}
                  />
                </TableCell>
                <Hidden xsDown>
                  <TableCell align="center">
                    <EText color="primary" variant="h6">
                      {separateThousands(
                        getTotalProductPrice(amount, p, discountsStatus, layout.homeConfig.todaysSpecial)
                      )}{' '}
                      Kč
                    </EText>
                    {getTotalProductPrice(amount, p, discountsStatus, layout.homeConfig.todaysSpecial) <
                      amount * (p.reccomendedPrice || 0) && (
                      <EText color="secondary" variant="caption">
                        Ušetříte{' '}
                        {separateThousands(
                          amount * (p.reccomendedPrice || 0) -
                            getTotalProductPrice(amount, p, discountsStatus, layout.homeConfig.todaysSpecial)
                        )}{' '}
                        Kč
                      </EText>
                    )}
                  </TableCell>
                </Hidden>
                <TableCell>
                  <div
                    className={classes.deleteProductBtn}
                    onClick={() => {
                      removeProductFromCart(p);
                    }}
                  >
                    ×
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </div>
      {discountsStatus.getPresents().length > 0 && presents.length > 0 && (
        <Gifts
          presents={discountsStatus.getPresents().map((p) => {
            return {
              present: presents.filter((pr) => pr.id === p.presentId)[0],
              ammount: p.ammount,
            };
          })}
        />
      )}
      <br />
      {convertGramsToKg(totalProductMass) >= 12 && (
        <>
          <Grid container>
            <Grid item style={{ width: 'fit-content' }}>
              <Tooltip
                title={
                  <EText style={{ color: 'white' }} variant="body2" fontSize="0.8rem">
                    *Dopravce limituje hmotnost zásilky na 14 kg. V případě překročení bude započítáno dodatečné
                    dopravné, případně budete kontaktováni.
                  </EText>
                }
                placement="right"
                arrow
              >
                <Typography
                  variant="body1"
                  style={{
                    color: convertGramsToKg(totalProductMass) > 14 ? 'red' : 'rgb(85, 50, 139)',
                  }}
                >
                  Přibližná hmotnost zásilky: {convertGramsToKg(totalProductMass)} kg / 14 kg*
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <br />
          <br />
        </>
      )}
      {discountsStatus.getAdditionalTotalDiscount() > 0 && (
        <>
          <EText variant="h6" color="secondary" align="right">
            Další slevy: {discountsStatus.getAdditionalTotalDiscount()} Kč
          </EText>
          <br />
          <br />
        </>
      )}
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <EText variant="h6">Slevový kód, voucher nebo dárkový poukaz</EText>
          <ETextfieldButton btnLabel="Uplatnit" label="Kód voucheru" onClick={alert} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EText variant="h6" align="right">
            Celková cena s DPH&nbsp;&nbsp;&nbsp;&nbsp;
            {separateThousands(totalPrice)} Kč
          </EText>
        </Grid>
      </Grid>
      <br />
    </>
  );
}
