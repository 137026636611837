import React from 'react';
import { createStyles, makeStyles, Theme, Hidden } from '@material-ui/core';
import EText from '../../Components/EText';
import { separateThousands } from '../../Utils/Common';
import Environment from '../../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: 26,
      backgroundColor: '#5C266C',
      display: 'flex',
      alignItems: 'center',
    },
    flexGrow: {
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    flexRowCol: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    flexCol: {
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      width: '16%',
    },
    savings: {
      marginRight: '8%',
      marginLeft: '8%',
    },
    checkIcon: {
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 42,
      paddingLeft: 10,
      paddingTop: 6,
      height: 48,
      width: 44,
      marginRight: '4%',
    },
    checkIconSmall: {
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      paddingLeft: 4,
      paddingTop: 1,
      height: 19,
      width: 16,
      margin: 8,
    },
    body: {
      paddingBottom: 32,
      borderBottom: '3px solid #5C266C',
    },
  })
);

interface IProps {
  actualPrice: number;
  expectedPrice: number;
}

export default function TotalSavingsPanel({ actualPrice, expectedPrice }: IProps) {
  const classes = useStyles();

  const savings = expectedPrice - actualPrice;

  return savings > 0 ? (
    <>
      <div className={classes.header}>
        <Hidden smDown>
          <img className={classes.logo} alt="Company logo" src={Environment.logoPath} />
          <div className={classes.flexGrow} />
        </Hidden>
        <div className={classes.checkIcon}>✔</div>
        <EText color="contrast" multiline variant="h6">
          Celková úspora <br />s {Environment.fullName3rdForm}
        </EText>
        <EText className={classes.savings} color="contrast" multiline variant="h6">
          {separateThousands(savings)} Kč
        </EText>
      </div>
      <div className={classes.body}>
        <br />
        <EText variant="h6">Další výhody s {Environment.fullName3rdForm}</EText>
        <br />
        <div className={classes.flexRowCol}>
          <div className={classes.flexCol}>
            <div className={classes.flex}>
              <div className={classes.checkIconSmall}>✔</div> <EText>Objednávat můžete 24 hodin denně</EText>
            </div>
            <div className={classes.flex}>
              <div className={classes.checkIconSmall}>✔</div>{' '}
              <EText>Doručení až domů nebo Osobní odběr - Zásilkovna</EText>
            </div>
          </div>
          <div className={classes.flexCol}>
            <div className={classes.flex}>
              <div className={classes.checkIconSmall}>✔</div>
              <EText>Přehledný sortiment produktů v jednotlivých kategoriích</EText>
            </div>
            <div className={classes.flex}>
              <div className={classes.checkIconSmall}>✔</div> <EText>Zajišťujeme pro Vás trvale nízké ceny</EText>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
