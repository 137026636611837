import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import SubcategoryCard from '../../Components/SubcategoryCard';
import EText from '../../Components/EText';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
  })
);

interface IProps {
  category: ICategory;
}
const CategoriesSuggestion = ({ category }: IProps) => {
  const [showAllCats, setShowAllCats] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Grid container>
        {category?.subCategories
          .filter((_, i) => i < 12 || showAllCats)
          .map((c) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={c.id}>
              <SubcategoryCard category={c} />
            </Grid>
          ))}
      </Grid>
      {(category?.subCategories || []).length > 12 && (
        <span onClick={() => setShowAllCats(!showAllCats)} className={classes.pointer}>
          <EText variant="body1" color="secondary" align="center">
            {!showAllCats && (
              <>
                Zobrazit více podkategorií
                <br />
              </>
            )}
            {showAllCats ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </EText>
        </span>
      )}
    </>
  );
};

export default CategoriesSuggestion;
