import { useQuery } from 'react-query';
import { catalogService } from '../Utils/ApiService';
import { ITreeCategory } from '../Types/base';

export const useCategories = () =>
  useQuery({
    queryKey: ['categories'],
    queryFn: () => catalogService.get<ITreeCategory[]>('categories/noauth/tree'),
    staleTime: 60 * 1000 * 9,
    onError: (error) => console.log('Error when fetching categories: ', error),
  });
