import React from 'react';
import { createStyles, makeStyles, Theme, Grid, Hidden } from '@material-ui/core';
import EText from './EText';
import { useHistory } from 'react-router-dom';
import { useLayout } from '../Layout/LayoutContext';
import { useAuth0 } from '@auth0/auth0-react';
import Environment from '../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    relative: {
      position: 'relative',
    },
    deliveryText: {
      position: 'absolute',
      left: 24,
      bottom: 24,
      width: 'calc(100% - 36px)',
      backgroundColor: 'rgba(0, 0, 0, 0.33)',
      marginLeft: -12,
      paddingLeft: 12,
    },
    mmLoginContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: 8,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: 16,
      height: 'calc(100% - 42px)',
    },
    deliveryImage: {
      width: '100%',
      aspectRatio: '30 / 23',
      borderRadius: 8,
      cursor: 'pointer',
    },
    registerButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontFamily: 'Roboto',
      padding: 16,
      borderRadius: 8,
      width: '92%',
      cursor: 'pointer',
      textAlign: 'center',
    },
  })
);

export default function DeliveryLeafletOrderPanel() {
  const classes = useStyles();
  const history = useHistory();
  const layout = useLayout();
  const { loginWithRedirect } = useAuth0();

  const validLeaflet = layout.homeConfig.leaflets?.filter(
    (l) =>
      (l.validFrom === undefined || l.validFrom === null || new Date(l.validFrom).getDate() <= new Date().getDate()) &&
      (l.validTo === undefined || l.validTo === null || new Date(l.validTo).getDate() >= new Date().getDate())
  )[0];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={4} lg={3} className={classes.relative}>
        <img
          onClick={() => history.push('/DeliveryAndPayment')}
          src="/img/delivery.webp"
          alt="delivery"
          className={classes.deliveryImage}
          loading='lazy'
        />
        <EText color="contrast" className={classes.deliveryText} multiline>
          Doprava a platba
          <br />
          Aktuální rychlost doručení
        </EText>
      </Grid>
      <Grid item xs={6} md={4} lg={3}>
        <img
          onClick={() => validLeaflet && window.open(validLeaflet.file, '_blank')}
          src="/img/leafletExtended.webp"
          alt="leaflet"
          className={classes.deliveryImage}
          loading='lazy'
        />
      </Grid>
      <Grid item xs={12} md={4} lg={6}>
        <div className={classes.mmLoginContainer} onClick={() => loginWithRedirect()}>
          <Hidden lgUp smDown>
            <EText variant="h6">Registrujte se</EText>
          </Hidden>
          <Hidden only="md">
            <EText variant="h6">{Environment.registerText}</EText>
          </Hidden>
          <Hidden only="md">
            <br />
          </Hidden>
          <EText>{Environment.fullName} Vám bude zasílat akční leták a další novinky.</EText>
          <EText>
            {process.env.REACT_APP_ESHOP_VARIANT === 'martin' ? (
              <>
                Zároveň mají členové klubu {Environment.fullName}{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the outer onClick from being triggered
                    history.push('/DeliveryAndPayment');
                  }}
                >
                  levnejší dopravu
                </span>
                .
              </>
            ) : null}
          </EText>
          <Hidden only="md">
            <br />
          </Hidden>
          {/* <ETextfieldButton
            btnLabel="Objednat"
            onClick={() => layout.success("Byl jste úspěšně přihlášen k Magistru Martinovi")}
            label="Váš e-mail"
          /> */}
          <div className={classes.registerButton}>Registrovat</div>
        </div>
      </Grid>
    </Grid>
  );
}
