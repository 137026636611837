const StripFormatter = (str: string | boolean) => str.toString().replace(/^\s+|\s+$/g, '');

const TitlesCaseFormatter = (str: string) => str.toString().toLowerCase().split(" ").map((s) => s.length > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s).join(" ");

const ZIPFormater = (str: string | boolean) => {
  str = str.toString().replace(/ /g, '');
  if (str.length > 3) str = str.substring(0, 3) + ' ' + str.substring(3);
  return str;
};

const CompIdFormater = (str: string | boolean) => {
  str = str.toString().replace(/ /g, '');
  if (str.length > 3) str = str.substring(0, 3) + ' ' + str.substring(3);
  if (str.length > 6) str = str.substring(0, 6) + ' ' + str.substring(6);
  return str;
};

const PhoneFormater = (str: string | boolean) => {
  str = str.toString().replace(/ /g, '');
  const pref = str.startsWith('+420') ? '+420 ' : '';
  str = str.replace('+420', '');
  if (str.length > 3) str = str.substring(0, 3) + ' ' + str.substring(3);
  if (str.length > 7) str = str.substring(0, 7) + ' ' + str.substring(7);
  return pref + str;
};

export const formatters = {
  ZIPFormater,
  CompIdFormater,
  PhoneFormater,
  StripFormatter,
  TitlesCaseFormatter
};
