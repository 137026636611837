import React from 'react';
import { createStyles, makeStyles, Theme, Container } from '@material-ui/core';
import EText from '../Components/EText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '64%',
      marginLeft: '18%',
    },
  })
);

export default function PaymentCancelled() {
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <EText variant="h4" align="center">
        Platba zamítnuta
      </EText>
      <br />
      <br />
      <img src="/img/paymentCancelled.webp" className={classes.image} alt="Payment cancelled" />
      <br />
      <br />
      <EText align="justify">
        Při pokusu o provedení platby došlo k chybě. Vaši objednávku jsme zaznamenali, avšak bez provedení platby ji
        nemůžeme dále zpracovávat. Platbu je možné opakovat. Instrukce pro opakování platby naleznete ve vaší emailové
        schránce. V případě jakýchkoliv dotazů nás, prosím, kontaktujte.
      </EText>
      <br />
      <br />
    </Container>
  );
}
