import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 50,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
    },
    pmButton: {
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    field: {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 48,
      fontFamily: 'Roboto',
    },
  })
);

interface IProps {
  value: number;
  onChange: (val: number) => void;
  min?: number;
  className?: string;
}

export default function ECounter({ value, onChange, className, min }: IProps) {
  const classes = useStyles();

  const minimum = min || 1;

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.pmButton} onClick={() => onChange(Math.max(minimum, value - 1))}>
        -
      </div>
      <div className={classes.field}>{value}</div>
      <div className={classes.pmButton} onClick={() => onChange(value + 1)}>
        +
      </div>
    </div>
  );
}
