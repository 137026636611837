import { Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    czechFlagIcon: {
      width: '25px',
    },
  })
);

const CzechProductIcon = () => {
  const classes = useStyles();
  return (
    <>
      <Tooltip title="Produkt českého výrobce">
        <img src="/img/czechFlagRound.webp" className={classes.czechFlagIcon} alt="Czech Republic flag" width={'100%'} height={'100%'} />
      </Tooltip>
    </>
  );
};

export default CzechProductIcon;
