import React from 'react';
import EText from './EText';
import ESelect from './ESelect';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IProps {
  sortState: SortBy;
  setSortState: (sortBy: SortBy) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowFlex: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const enum SortBy {
  RELEVANCE = 'relevance',
  NAME = 'name',
  PRICE_DESC = 'priceDesc',
  PRICE_ASC = 'price',
  UNIT_PRICE_DESC = 'unitPriceDesc',
  UNIT_PRICE_ASC = 'unitPrice',
}

const SortSelect = ({ sortState, setSortState }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.rowFlex}>
        <EText>Řazení: </EText>&nbsp;&nbsp;&nbsp;
        <ESelect
          options={[
            { key: SortBy.RELEVANCE, value: 'Relevance' },
            { key: SortBy.NAME, value: 'Název' },
            { key: SortBy.PRICE_DESC, value: 'Od nejdražšího' },
            { key: SortBy.PRICE_ASC, value: 'Od nejlevnějšího' },
            {
              key: SortBy.UNIT_PRICE_DESC,
              value: 'Od nejvyšší ceny za jednotku',
            },
            { key: SortBy.UNIT_PRICE_ASC, value: 'Od nejnižší ceny za jednotku' },
          ]}
          value={sortState}
          onChange={setSortState}
        />
      </div>
    </>
  );
};

export default SortSelect;
