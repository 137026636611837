import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import ProductsPreviewCard from '../../Components/ProductPreviewCard';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import useWindowDimensions from '../../Hooks/GetWindowDimenstions';

interface IProps {
  productPagesToShow: ProductPagesToShow;
  bannerToShow?: string;
  areProductsLoading: boolean;
  areProductsFetching: boolean;
  totalPages: number | undefined;
  isLastPage: boolean | undefined;
  currentPage: number;
  onPaginationChange: (newPageNumber: number) => void;
  onLoadMoreButtonClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomLayout: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
    },
  })
);

const RightPagination = withStyles({
  ul: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
})(Pagination);

export interface ProductPagesToShow {
  containedPages: number[];
  products: IProduct[];
}

const PaginatedProducts = ({
  productPagesToShow,
  bannerToShow,
  areProductsFetching,
  areProductsLoading,
  totalPages = 0,
  isLastPage = true,
  currentPage,
  onPaginationChange,
  onLoadMoreButtonClick,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const productsPerRow = width <= theme.breakpoints.width('md') ? 2 : width <= theme.breakpoints.width('lg') ? 3 : 4;

  return (
    <>
      <Grid container>
        {productPagesToShow.products.map((prd, i) => (
          <React.Fragment key={prd.id}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ProductsPreviewCard product={prd} />
            </Grid>
            {bannerToShow && i === 2 * productsPerRow - 1 && (
              <Grid item xs={12}>
                <img
                  style={{
                    width: 'calc(100% - 32px)',
                    paddingBottom: 16,
                  }}
                  alt="banner"
                  src={bannerToShow}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
      {areProductsLoading ? (
        <div className={classes.flexCenter}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.bottomLayout}>
            <Button style={{ float: 'left' }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
              Nahoru
            </Button>
            <RightPagination
              count={totalPages}
              variant="outlined"
              page={currentPage + 1}
              onChange={(e, p) => onPaginationChange(p)}
              color="secondary"
              shape="rounded"
              hidePrevButton
              hideNextButton
              disabled={areProductsFetching}
            />
          </div>

          <div className={classes.flexCenter}>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              disabled={isLastPage}
              onClick={() => onLoadMoreButtonClick()}
            >
              Načíst další produkty
            </Button>
          </div>
        </>
      )}{' '}
    </>
  );
};

export default PaginatedProducts;
