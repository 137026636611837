import React from 'react';
import { Helmet } from 'react-helmet-async';

export const SHOW_HEUREKA_WIDGETS = process.env.REACT_APP_ESHOP_VARIANT === 'marek';

// This should be added to some top level component to be able to show badge
export const HeurekaInlineBadgeInitScript = () => {
  return (
    <>
      {SHOW_HEUREKA_WIDGETS ? (
        <Helmet>
          <script src="/js/heurekaInlineBadge.js" type="text/javascript"></script>
        </Helmet>
      ) : (
        <></>
      )}
    </>
  );
};

const HeurekaInlineBadge = () => {
  return <>{SHOW_HEUREKA_WIDGETS ? <div id="showHeurekaBadgeHere-11"></div> : <></>}</>;
};

export default HeurekaInlineBadge;
