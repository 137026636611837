import React, { useState } from 'react';
import { Container, createStyles, makeStyles, Theme, Grid, useTheme } from '@material-ui/core';
import ProductPreviewCard from '../Components/ProductPreviewCard';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import useWindowDimensions from '../Hooks/GetWindowDimenstions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { SlideRenderProps, virtualize } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    dotsPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    dotBase: {
      width: 10,
      height: 10,
      margin: 2,
      marginTop: 6,
      cursor: 'pointer',
      borderRadius: '50%',
    },
    emptyDot: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    filledDot: {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    arrowBase: {
      position: 'absolute',
      cursor: 'pointer',
      width: 32,
      height: 32,
      borderRadius: '50%',
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 'calc(50% - 32px)',
      fontSize: 20,
    },
    leftArrow: {
      left: -48,
    },
    rightArrow: {
      right: -48,
    },
    smallArrow: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  })
);

interface IProps {
  products: IProduct[];
}

export default function ProductsPreviewPanel({ products }: IProps) {
  const classes = useStyles();
  const [site, setSite] = useState(0);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const moveRight = () => setSite((site + 1) % sitesCount);
  const moveLeft = () => setSite((site + sitesCount - 1) % sitesCount);

  const productsPerPage =
    width <= theme.breakpoints.width('sm')
      ? 1
      : width <= theme.breakpoints.width('md')
        ? 2
        : width <= theme.breakpoints.width('lg')
          ? 3
          : 4;

  const sitesCount = Math.ceil(products.length / productsPerPage);

  const arrowsOutside = sitesCount > 1 && width >= 160 + theme.breakpoints.width('lg');

  const contentRenderer = ({ key, index }: SlideRenderProps) => (
    <Grid container key={key}>
      {products.slice(index * productsPerPage, Math.min((index + 1) * productsPerPage, products.length)).map((prd) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={prd.id}>
          <ProductPreviewCard product={prd} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {arrowsOutside && (
          <>
            <div className={`${classes.arrowBase} ${classes.leftArrow}`} onClick={moveLeft}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className={`${classes.arrowBase} ${classes.rightArrow}`} onClick={moveRight}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </>
        )}
        {
          //@ts-ignore
          <VirtualizeSwipeableViews
            index={site}
            onChangeIndex={(index) => setSite(index)}
            slideRenderer={contentRenderer}
          />
        }
        <br />
        {sitesCount > 1 && (
          <div className={classes.dotsPanel}>
            <span onClick={moveLeft} className={classes.smallArrow}>
              &lang;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {Array.from(Array(sitesCount)).map((_, i) => (
              <div
                key={i}
                onClick={() => setSite(i)}
                className={`${classes.dotBase} ${i === site ? classes.filledDot : classes.emptyDot}`}
              >
                &nbsp;
              </div>
            ))}
            <span onClick={moveRight} className={classes.smallArrow}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&rang;
            </span>
          </div>
        )}
        <br />
      </Container>
    </>
  );
}
