import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

interface IPorps {
  variant?:
    | 'inherit'
    | 'button'
    | 'overline'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'srOnly'
    | undefined;
  bold?: boolean;
  underline?: boolean;
  children?: React.ReactNode;
  className?: string;
  inline?: boolean;
  color?: 'primary' | 'secondary' | 'contrast';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  multiline?: boolean;
  asDiv?: boolean;
  onClick?: () => void;
  ref?: any;
  style?: React.CSSProperties;
  fontSize?: string;
  lineHeight?: string;
}

export default function EText({
  variant,
  asDiv,
  bold,
  underline,
  children,
  className,
  inline,
  color,
  align,
  multiline,
  onClick,
  ref,
  style,
  fontSize,
  lineHeight,
}: IPorps) {
  const theme = useTheme();

  return asDiv ? (
    <Typography
      variant={variant}
      className={`${className || ''}`}
      style={{
        color:
          color === 'secondary'
            ? theme.palette.secondary.main
            : color === 'contrast'
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main,
        whiteSpace: multiline ? 'pre-line' : 'inherit',
        textDecoration: underline ? 'underline' : 'none',
        fontSize: fontSize,
        lineHeight: lineHeight,
        ...(style === undefined ? {} : style),
      }}
      noWrap={inline === true}
      component="div"
      align={align}
      onClick={onClick}
      ref={ref}
    >
      {bold ? <b>{children}</b> : children}
    </Typography>
  ) : (
    <Typography
      variant={variant}
      className={`${className || ''}`}
      style={{
        color:
          color === 'secondary'
            ? theme.palette.secondary.main
            : color === 'contrast'
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main,
        whiteSpace: multiline ? 'pre-line' : 'inherit',
        textDecoration: underline ? 'underline' : 'none',
        fontSize: fontSize,
        lineHeight: lineHeight,
        ...(style === undefined ? {} : style),
      }}
      noWrap={inline === true}
      align={align}
      onClick={onClick}
    >
      {bold ? <b>{children}</b> : children}
    </Typography>
  );
}
