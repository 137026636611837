import React from 'react';
import { Avatar, Button, Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import EText from '../Components/EText';
import { Done } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { secureId } from '../Utils/Orders';
import Environment from '../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    root: {
      // marginTop: theme.spacing(8),
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    banner: {
      width: '80%',
    },
  })
);

interface IProps {
  orderId: number;
}

export default function OrderCreatedConfirm({ orderId }: IProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.root} maxWidth="lg">
      <img src={Environment.orderFinishImagePath} className={classes.banner} alt="orderReceivedBanner" />
      <Container className={classes.root} maxWidth="sm">
        <Avatar className={classes.avatar}>
          <Done />
        </Avatar>
        <br />
        <EText variant="h5" align="center">
          Vaše objednávka byla úspěšně odeslána
        </EText>
        <br />
        <br />
        <EText align="justify">
          Během několika minut Vám pošleme email s potvrzením objednávky. Pokud Vám tento email nepřišel, zkontrolujte,
          prosím, spam a případně nás, prosím, kontaktujte.
        </EText>
        <br />
        <br />
        <br />

        <Button variant="contained" color="secondary" onClick={() => history.push(`/Order/${secureId(orderId)}`)}>
          Zobrazit detail objednávky
        </Button>
        <br />
        <br />
      </Container>
    </Container>
  );
}
