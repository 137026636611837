import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import StepCard from '../../Components/StepCard';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import EText from '../../Components/EText';
import { ExitToApp, Person, Toc } from '@material-ui/icons';
import Environment from '../../Environments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  })
);

interface IProps {
  selected: 'orders' | 'profile';
}

export default function CommonHeader({ selected }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth0();

  return (
    <>
      <EText variant="h4" align="center">
        Osobní profil u {Environment.fullName2ndForm}
      </EText>
      <br />
      <br />
      <div className={classes.root}>
        <StepCard
          onClick={() => history.push('/MyOrders')}
          icon={Toc}
          selected={selected === 'orders'}
          text="Objednávky a faktury"
        />
        <StepCard
          onClick={() => history.push('/Profile')}
          icon={Person}
          selected={selected === 'profile'}
          text="Upravit osobní údaje"
        />
        <StepCard onClick={() => logout()} icon={ExitToApp} text="Odhlásit se" />
      </div>
    </>
  );
}
