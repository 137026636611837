import React, { useState, createContext, useContext, useEffect } from 'react';
import { LinearProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Layout from './Layout';
import { imagesService } from '../Utils/ApiService';
import Environment from '../Environments';
import ConfirmModal from './ConfirmModal';

type BannerType = {
  file: string;
  name?: string;
  link?: string;
  validFrom?: Date;
  validTo?: Date;
};

type HomeConfigType = {
  banners?: BannerType[];
  miniBanners?: BannerType[];
  leaflets?: { file: string; validFrom?: Date; validTo?: Date }[];
  shippingPaymentLink: string;
  productSeries: {
    products: number[];
    validFrom?: Date | string;
    validTo?: Date | string;
    name: string;
  }[];
  valid: boolean;
  todaysSpecial: {
    productId: number;
    validAt: Date | string;
    specialPrice?: number;
  }[];
  disableCart?: boolean;
};

export const LayoutContext = createContext({
  success: (message: string) => {},
  error: (message: string) => {},
  warning: (message: string) => {},
  info: (message: string) => {},
  customAlert: (message: string, bg: string, color: string) => {},
  setIsLoading: (isLoading: boolean) => {},
  confirm: (title: string, message: string, onConfirm: () => void) => {},
  homeConfig: {
    shippingPaymentLink: '',
    productSeries: [],
    valid: false,
    todaysSpecial: [],
    disableCart: false,
  } as HomeConfigType,
  isLoading: false,
  setSuklVerifyLogoVisible: (visible: boolean) => {},
  setVeterinaryLogoVisible: (visible: boolean) => {},
  changeLocation: (loc: string) => {},
});

export enum AlertSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export function LayoutProvider(props: { children?: React.ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState(AlertSeverity.SUCCESS);
  const [customAlertProps, setCustomAlertProps] = useState({
    message: '',
    color: '',
    bg: '',
  });
  const [confirmData, setConfirmData] = useState({
    opened: false,
    message: '',
    title: '',
    onConfirm: () => {},
  });
  const [homeConfig, setHomeConfig] = useState<HomeConfigType>({
    shippingPaymentLink: '',
    productSeries: [],
    valid: false,
    todaysSpecial: [],
  });

  const [suklVerifyLogoVisible, setSuklVerifyLogoVisible] = useState(false);
  const [veterinaryVerifyLogoVisible, setVeterinaryVerifyLogoVisible] = useState(false);
  const [href, setHref] = useState<string>(window.location.href);

  useEffect(() => {
    imagesService.get(
      '/text',
      {
        file: `${Environment.domain.replace(/[.]/g, '_')}_homeSite.json`,
        location: 'config',
      },
      {
        success: (data: any) => setHomeConfig({ ...data, valid: true }),
        error: console.log,
      }
    );
  }, []);

  const success = (message: string) => {
    setAlertSeverity(AlertSeverity.SUCCESS);
    setAlertMessage(message);
  };
  const error = (message: string) => {
    setAlertSeverity(AlertSeverity.ERROR);
    setAlertMessage(message);
  };
  const warning = (message: string) => {
    setAlertSeverity(AlertSeverity.WARNING);
    setAlertMessage(message);
  };
  const info = (message: string) => {
    setAlertSeverity(AlertSeverity.INFO);
    setAlertMessage(message);
  };

  const customAlert = (message: string, bg: string, color: string) =>
    setCustomAlertProps({
      message: message,
      bg: bg,
      color: color,
    });

  const confirm = (title: string, message: string, onConfirm: () => void) => {
    setConfirmData({
      message: message,
      onConfirm: onConfirm,
      opened: true,
      title: title,
    });
  };

  const changeLocation = (loc: string) => setHref(loc);

  return (
    <LayoutContext.Provider
      value={{
        success: success,
        error: error,
        warning: warning,
        info: info,
        customAlert: customAlert,
        confirm: confirm,
        setIsLoading: setLoading,
        isLoading: loading,
        homeConfig: homeConfig,
        setSuklVerifyLogoVisible: setSuklVerifyLogoVisible,
        setVeterinaryLogoVisible: setVeterinaryVerifyLogoVisible,
        changeLocation: changeLocation,
      }}
    >
      <Layout
        suklVerifyLogoVisible={suklVerifyLogoVisible}
        veterinaryVerifyLogoVisible={veterinaryVerifyLogoVisible}
        href={href}
      >
        {props.children}
        <Snackbar
          open={Boolean(alertMessage)}
          onClose={() => setAlertMessage('')}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setAlertMessage('')} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(customAlertProps.message)}
          onClose={() => setCustomAlertProps({ ...customAlertProps, message: '' })}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            style={{
              color: customAlertProps.color,
              backgroundColor: customAlertProps.bg,
            }}
            onClose={() => setCustomAlertProps({ ...customAlertProps, message: '' })}
            icon={false}
          >
            {customAlertProps.message}
          </Alert>
        </Snackbar>
        <LinearProgress
          style={{
            position: 'fixed',
            bottom: '0',
            height: '4px',
            width: '100%',
          }}
          hidden={!loading}
        />
        <ConfirmModal
          opened={confirmData.opened}
          close={() => setConfirmData({ ...confirmData, opened: false })}
          confirm={confirmData.onConfirm}
          message={confirmData.message}
          title={confirmData.title}
        />
      </Layout>
    </LayoutContext.Provider>
  );
}

export const useLayout = () => useContext(LayoutContext);
