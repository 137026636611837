import React, { useEffect, useState } from 'react';
import {
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Theme,
} from '@material-ui/core';
import { useLayout } from './LayoutContext';
import { useHistory } from 'react-router-dom';
import { ITreeCategory } from '../Types/base';
import { catalogService } from '../Utils/ApiService';
import { Menu } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderButtonsStack from '../Components/HeaderButtonsStack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headList: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    list: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      flexGrow: 1,
    },
    bottomList: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    headerButtonStack: {
      backgroundColor: theme.palette.primary.main,
      padding: '5px',
    },
  })
);

const loadCategoriesFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('categoriesTree') || '[]') as ITreeCategory[];
};

export default function MobileMenu() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<ITreeCategory[]>(loadCategoriesFromLocalStorage());
  const layout = useLayout();
  const history = useHistory();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    catalogService.get('categories/noauth/tree', [], {
      success: (cats: ITreeCategory[]) => {
        setCategories(cats);
        localStorage.setItem('categoriesTree', JSON.stringify(cats));
      },
      error: layout.error,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <IconButton aria-label="button" onClick={() => setOpen(true)}>
        <Menu />
      </IconButton>
      <SwipeableDrawer anchor="left" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <List className={classes.headList}>
          <ListItem
            onClick={() => {
              history.push(`/`);
              setOpen(false);
            }}
            button
          >
            <ListItemText primary="Akce a slevy" />
          </ListItem>
        </List>
        <List className={classes.list}>
          {categories.map((c) => (
            <ListItem
              key={c.description}
              onClick={() => {
                history.push(`/offer/category/${c.id}`);
                setOpen(false);
              }}
              button
            >
              <ListItemText primary={c.description} />
            </ListItem>
          ))}
        </List>
        <div className={classes.headerButtonStack}>
          <HeaderButtonsStack direction="column" textColor="white" />
        </div>
        <List className={classes.bottomList}>
          <hr />
          {(isAuthenticated && (
            <ListItem
              onClick={() => {
                history.push('/MyOrders');
                setOpen(false);
              }}
              button
            >
              <ListItemText primary="Můj účet" />
            </ListItem>
          )) || (
            <ListItem
              onClick={() =>
                loginWithRedirect({
                  extraParams: { ui_locales: 'de', language: `it` },
                })
              }
              button
            >
              <ListItemText primary="Přihlásit se" />
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
    </>
  );
}
