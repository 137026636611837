import { useQuery } from 'react-query';
import { ordersService } from '../Utils/ApiService';
import { PickupPlace } from '@magistrmartin/eshop-frontend-shared';

export const usePickupPlaces = () => {
  return useQuery({
    queryKey: ['pickupPlaces'],
    queryFn: () => ordersService.get<PickupPlace[]>('/infrastructure/pickupPlaces'),
    staleTime: 60 * 1000 * 9,
    placeholderData: [],
    onError: (error) => console.log('Error when fetching pickup places: ', error),
  });
};
