import { useQuery } from 'react-query';
import { catalogService } from '../Utils/ApiService';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import PaginatedResponse from '../Types/paginatedResponse';
import { IFilterState } from '../Components/Filters';
import { OfferParamsState } from '../Pages/Offer/PaginatedOffer';
import { SortBy } from '../Components/SortSelect';
import Environment from '../Environments';
interface SortDetails {
  sortBy: string
  sortOrder: 'desc' | 'asc'
}

const getSortDetailsFromEnum = (sortBy: SortBy): SortDetails => {
  switch (sortBy) {
    case SortBy.RELEVANCE:
      return { sortBy: 'relevanceScore', sortOrder: 'desc' }
    case SortBy.NAME:
      return { sortBy: 'title', sortOrder: 'asc' }
    case SortBy.PRICE_ASC:
      return { sortBy: Environment.useHeurekaPrices ? 'finalSellingPriceHeureka' : 'finalSellingPrice', sortOrder: 'asc' }
    case SortBy.PRICE_DESC:
      return { sortBy: Environment.useHeurekaPrices ? 'finalSellingPriceHeureka' : 'finalSellingPrice', sortOrder: 'desc' }
    case SortBy.UNIT_PRICE_ASC:
      return { sortBy: Environment.useHeurekaPrices ? 'finalSellingUnitPriceHeureka' : 'finalSellingUnitPrice', sortOrder: 'asc' }
    case SortBy.UNIT_PRICE_DESC:
      return { sortBy: Environment.useHeurekaPrices ? 'finalSellingUnitPriceHeureka' : 'finalSellingUnitPrice', sortOrder: 'desc' }
  }
}

export const useProduct = (id: number | undefined) =>
  useQuery({
    queryKey: ['product', id],
    queryFn: () => catalogService.get<IProduct>('/products/noauth', { id }),
    onError: (error) => console.log('Error when fetching product: ', error),
    enabled: id !== undefined && id !== null,
  });

const fetchProductsPage = (
  filterType: string,
  filterId: string,
  page: number,
  limit: number,
  fields: string,
  filters: IFilterState | undefined,
  sortBy: SortBy
) =>
  catalogService.post<PaginatedResponse<IProduct[]>>(
    `products/noauth/filter/${filterType}`,
    {
      filterValue: filterId,
      page,
      limit,
      fields,
      sortBy: getSortDetailsFromEnum(sortBy).sortBy,
      sortOrder: getSortDetailsFromEnum(sortBy).sortOrder
    },
    filters
  );

export const useProductsPaginated = (
  filterType: string,
  filterId: string,
  page: number,
  itemsPerPage: number,
  fields: string,
  additionalParams: OfferParamsState
) =>
  useQuery({
    queryKey: [
      'products',
      'itemsPerPage',
      itemsPerPage,
      filterType,
      filterId,
      'additionalParams',
      additionalParams,
      'page',
      page,
    ],
    queryFn: () => fetchProductsPage(filterType, filterId, page, itemsPerPage, fields, additionalParams.filter, additionalParams.sortBy),
  });
