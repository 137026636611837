import {
  IBaseDiscount,
  ICentralPresent,
  IFreeDelivery,
  IMixDiscount,
  IPresent,
  ISpecialDiscount,
} from '@magistrmartin/eshop-frontend-shared';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { reducers } from './Reducers';
import { containsDiscount, getPrice, isDiscountValid } from './Product';
import { distinctBy } from './Arrays';
import { IDailySpecial } from '../Types/base';
import Environment from '../Environments';
import CartItem from '../Types/cartItem';

export class DiscountsStatus {
  private cartContent: CartItem[];
  private freeProducts: { [index: number]: number };
  private additionalDiscounts: { [index: number]: number };
  private presents: { presentId: number; ammount: number }[];
  private todaysSpecials: IDailySpecial[];

  constructor(
    cartContent: CartItem[],
    allCentralPresents: ICentralPresent[],
    isAuthenticated: boolean,
    todaysSpecials: IDailySpecial[]
  ) {
    this.todaysSpecials = todaysSpecials;
    this.cartContent = cartContent;
  
    if (Environment.useHeurekaPrices === true || Environment.useZboziPrices === true) {
      this.freeProducts = [];
      this.additionalDiscounts = {};
      this.presents = [];
    }
    else  {
      this.freeProducts = this.getFreeProducts();
      this.additionalDiscounts = this.getAdditionalDiscounts();
      this.presents = [...this.getAllPresents(), ...this.getCentralPresents(cartContent, allCentralPresents, isAuthenticated)];
    }
  }

  getSymbolicPriceForFreeProducts = () => this.presents.map((p) => p.ammount).reduce(reducers.sum, 0);

  getNumberOfFreeUnits = (product: IProduct) => (product.id in this.freeProducts && this.freeProducts[product.id]) || 0;

  getAdditionalDiscountForProduct = (product: IProduct) =>
    (product.id in this.additionalDiscounts && this.additionalDiscounts[product.id]) || 0;

  isDeliveryFree = () =>
    this.getFreeDeliveries(this.cartContent.map((p) => p.product))
      .map((d) => this.isDiscountConditionFulfilled(d))
      .reduce(reducers.or, false);

  getPresents = () => [...this.presents];

  getAdditionalTotalDiscount = () =>
    Environment.useHeurekaPrices || Environment.useZboziPrices
      ? 0
      : this.getSpecialDiscounts(this.cartContent.map((p) => p.product))
          .filter((d) => this.isDiscountConditionFulfilled(d))
          .map((d) => (d.discount?.value || 0) * Math.floor(this.getAppliedAmmount(d) / (d.minimum?.value || 0)))
          .reduce(reducers.sum, 0);

  private getFreeProducts = () => {
    const mixDiscounts = this.getMixDiscounts(this.cartContent.map((p) => p.product));
    const res: { [index: number]: number } = {};
    mixDiscounts.forEach((d: IMixDiscount) => {
      const sortedDiscountProducts = this.cartContent
        .filter((p) => p.product.mixDiscount && p.product.mixDiscount.id === d.id)
        .sort((a, b) => getPrice(b.product, this.todaysSpecials) - getPrice(a.product, this.todaysSpecials));
      const totalProducts = sortedDiscountProducts.map((p) => p.amount).reduce(reducers.sum, 0);
      for (let i = d.minimum?.value || 9999999; i < totalProducts; i += (d.minimum?.value || 9999999) + d.free) {
        for (let j = 0; j < d.free && j + i < totalProducts; j++) {
          const act = this.getNthProduct(i + j, sortedDiscountProducts);
          res[act?.id || -1] = (res[act?.id || -1] || 0) + 1;
        }
      }
    });
    return res;
  };

  private getNthProduct(n: number, products: CartItem[]) {
    for (let i = 0; i < products.length; i++) {
      if (n < products[i].amount) return products[i].product;
      n -= products[i].amount;
    }
    return undefined;
  }

  private getMixDiscounts = (products: IProduct[]) =>
    distinctBy(
      products
        .filter((p) => p.mixDiscount)
        .map((p) => p.mixDiscount as IMixDiscount)
        .filter((d) => isDiscountValid(d)),
      (x) => x.id
    );

  private getSpecialDiscounts = (products: IProduct[]): ISpecialDiscount[] =>
    distinctBy(
      products
        .filter((p) => p.specialDiscount)
        .map((p) => p.specialDiscount as ISpecialDiscount)
        .filter((d) => isDiscountValid(d)),
      (x) => x.id
    );

  private getFreeDeliveries = (products: IProduct[]) =>
    distinctBy(
      products
        .filter((p) => p.freeDelivery)
        .map((p) => p.freeDelivery as IFreeDelivery)
        .filter((d) => isDiscountValid(d)),
      (x) => x.id
    );

  private getPresentDiscounts = (products: IProduct[]) =>
    distinctBy(
      products
        .filter((p) => p.present)
        .map((p) => p.present as IPresent)
        .filter((d) => isDiscountValid(d)),
      (x) => x.id
    ) as IPresent[];

  private getAppliedAmmount = (discount: IBaseDiscount) =>
    this.cartContent
      .filter((p) => containsDiscount(p.product, discount))
      .map((p) => p.amount)
      .reduce(reducers.sum, 0);

  private getAppliedPrice = (discount: IBaseDiscount) =>
    this.cartContent
      .filter((p) => containsDiscount(p.product, discount))
      .map((p) => p.amount * getPrice(p.product, this.todaysSpecials))
      .reduce(reducers.sum, 0);

  private isDiscountConditionFulfilled = (dis: IBaseDiscount) => {
    const appliedAmmount = this.getAppliedAmmount(dis);
    const appliedPrice = this.getAppliedPrice(dis);
    if (!dis.minimum) return false;
    if (dis.minimum.factor === 'items') return appliedAmmount >= (dis.minimum.value || 9999999);
    if (dis.minimum.factor === 'price') return appliedPrice >= (dis.minimum.value || 9999999);
    return false;
  };

  private getAdditionalDiscounts = () => {
    const res: { [index: number]: number } = {};
    this.cartContent
      .filter((p) => p.product.secondItemDiscount && p.amount > 1)
      .forEach(({ product, amount }) => {
        const appliedAmmount = Math.floor(amount / 2);
        const discount =
          product.secondItemDiscount?.discount?.factor === 'relative'
            ? Math.floor(
                ((product.secondItemDiscount.discount.value || 0) / 100) * getPrice(product, this.todaysSpecials)
              )
            : product.secondItemDiscount?.discount?.value || 0;
        res[product.id] = appliedAmmount * discount;
      });
    return res;
  };

  private getAllPresents = () => {
    const res: { presentId: number; ammount: number }[] = [];
    const presDiscounts = this.getPresentDiscounts(this.cartContent.map((p) => p.product));
    presDiscounts.forEach((d) => {
      const ammount = this.getAppliedAmmount(d);
      const spent = this.getAppliedPrice(d);
      const count =
        d.minimum?.factor === 'items'
          ? Math.floor(ammount / (d.minimum?.value || 1))
          : Math.floor(spent / (d.minimum?.value || 1));
      if (count > 0) res.push({ presentId: d.targetId, ammount: d.single ? 1 : count });
    });
    return res;
  };

  private getCentralPresents = (
    cartContent: CartItem[],
    allCentralPresents: ICentralPresent[],
    isAuthenticated: boolean
  ) => {
    const res: { presentId: number; ammount: number }[] = [];
    allCentralPresents
      .filter(
        (p) =>
          (p.validity?.from === undefined || new Date(p.validity.from).getTime() < new Date().getTime()) &&
          (p.validity?.to === undefined || new Date(p.validity.to).getTime() > new Date().getTime())
      )
      .filter(
        (p) =>
          p.targetGroup === 'all' ||
          (isAuthenticated && p.targetGroup === 'registered') ||
          (!isAuthenticated && p.targetGroup === 'unregistered')
      )
      .forEach((p) => {
        const ammount = cartContent.map((cc) => cc.amount).reduce(reducers.sum, 0);
        const spent = cartContent
          .map((pp) => pp.amount * getPrice(pp.product, this.todaysSpecials))
          .reduce(reducers.sum, 0);
        const count =
          p.minimum?.factor === 'items'
            ? Math.floor(ammount / (p.minimum?.value || 1))
            : Math.floor(spent / (p.minimum?.value || 1));
        if (count > 0) res.push({ presentId: p.targetId, ammount: p.single ? 1 : count });
      });
    return res;
  };
}
