import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      backgroundColor: '#84b517',
      borderRadius: '100%',
      animation: '$blinking 1.0s ease infinite',
    },

    '@keyframes blinking': {
      '50%': {
        opacity: 0,
      },
    },
  })
);

const BlinkingDot = () => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.dot}></span>
    </>
  );
};

export default BlinkingDot;
