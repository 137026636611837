import { Badge, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShoppingCart } from '@material-ui/icons';
import { useLayout } from '../Layout/LayoutContext';
import { round, separateThousands } from '../Utils/Common';

interface IProps {
  itemsInShoppingCart: number;
  priceInShoppingCart: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shoppingCart: {
      backgroundColor: '#09BCD0',
      color: 'white',
      marginLeft: 22,
      '&:hover': {
        backgroundColor: '#09BCD0',
        filter: 'brightness(90%)',
      },
    },
    cartBadge: {
      transform: 'scale(1) translate(0, 50%)',
    },
  })
);

const ShoppingCartButton = ({ itemsInShoppingCart, priceInShoppingCart }: IProps) => {
  const history = useHistory();
  const layout = useLayout();
  const classes = useStyles();
  return (
    <>
      <Badge color="primary" overlap="circular" badgeContent={itemsInShoppingCart}>
        <Badge
          color="secondary"
          badgeContent={itemsInShoppingCart === 0 ? 'KOŠÍK' : `${separateThousands(round(priceInShoppingCart, 2))} Kč`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{ badge: classes.cartBadge }}
        >
          <IconButton
            className={classes.shoppingCart}
            aria-label="shopping Cart"
            disabled={layout.homeConfig.disableCart}
            onClick={() => {
              if (itemsInShoppingCart > 0) history.push('/FinnishOrder');
              else layout.warning('Nákupní košík je prázdný.');
            }}
          >
            <ShoppingCart />
          </IconButton>
        </Badge>
      </Badge>
    </>
  );
};

export default ShoppingCartButton;
