import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { ITreeCategory } from '../Types/base';
import EText from './EText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 24px)',
      height: '64px',
      paddingLeft: 12,
      backgroundColor: '#F6F8FB',
      borderRadius: 12,
      margin: 12,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  })
);

interface IProps {
  category: ICategory | ITreeCategory;
}

export default function SubcategoryCard({ category }: IProps) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div
      onClick={() =>
        history.push(
          `/offer/category/${category.description?.replace(/%/g, '')?.replace(/\//g, '')?.replace(/[ ]/g, '-')}-${category.id}`
        )
      }
      className={classes.root}
    >
      <EText variant="body1">{category.description}</EText>
    </div>
  );
}
