import React from 'react';
import {
  createStyles,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from '@material-ui/core';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  })
)(InputBase);

interface IProps {
  className?: string;
  label?: string;
  fullWidth?: boolean;
  value: any;
  onChange: (val: any) => void;
  options: { key: any; value: any }[];
}

export default function ESelect({ className, label, value, onChange, options, fullWidth }: IProps) {
  return (
    <FormControl className={className} fullWidth={fullWidth}>
      {(label?.length || 0) > 0 && <InputLabel>{label}</InputLabel>}
      <Select value={value} onChange={(e) => onChange(e.target.value)} fullWidth={fullWidth} input={<BootstrapInput />}>
        {options.map((o) => (
          <MenuItem key={o.key} value={o.key}>
            {o.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
