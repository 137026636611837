import React from 'react';
import {
  createStyles,
  makeStyles,
  SvgIcon,
  SvgIconTypeMap,
  Theme,
  useTheme,
  Hidden,
  IconButton,
  Badge,
} from '@material-ui/core';
import EText from './EText';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '98%',
      display: 'flex',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      alignItems: 'center',
      padding: 12,
    },
    iconContainer: {
      height: 50,
      width: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
    },
    icon: {
      color: theme.palette.primary.contrastText,
      fontSize: 32,
    },
    text: {
      flexGrow: 1,
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    customBadge: {
      backgroundColor: '#05bcd1',
      color: 'white',
      fontWeight: 'bold',
      fontSize: 12,
    },
  })
);

interface IProps {
  selected?: boolean;
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick?: () => void;
  inactive?: boolean;
  finished?: boolean;
  isLast?: boolean;
}

export default function StepCard({ icon, selected, text, onClick, finished, isLast }: IProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Hidden smDown>
        <div
          onClick={onClick}
          className={classes.root}
          style={{
            background: selected ? theme.palette.primary.main : 'initial',
            cursor: onClick === undefined ? 'initial' : 'pointer',
            marginRight: isLast ? 0 : '2%',
          }}
        >
          {finished ? (
            <Badge classes={{ badge: classes.customBadge }} overlap="circular" badgeContent="✓">
              <div className={classes.iconContainer}>
                <SvgIcon component={icon} className={classes.icon} />
              </div>
            </Badge>
          ) : (
            <div className={classes.iconContainer}>
              <SvgIcon component={icon} className={classes.icon} />
            </div>
          )}
          <EText align="center" className={classes.text} color={selected ? 'contrast' : 'primary'}>
            {text}
          </EText>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div onClick={onClick} className={classes.flexColumn}>
          <IconButton
            className={classes.iconContainer}
            style={{
              backgroundColor: selected ? theme.palette.secondary.main : 'rgba(0, 0, 0, 0.54)',
              color: theme.palette.secondary.contrastText,
            }}
            aria-label={text}
          >
            <SvgIcon component={icon} />
          </IconButton>
          <EText variant="caption" align="center">
            {text}
          </EText>
        </div>
      </Hidden>
    </>
  );
}
