import React, { useEffect, useState } from 'react';
import EText from './EText';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      width: '100%',
      borderRadius: 8,
      aspectRatio: '77/25'
    },
    stepCard: {
      borderRadius: 8,
      border: `1px solid ${theme.palette.primary.main}`,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 64,
      cursor: 'pointer',
      textAlign: 'center',
    },
    actStepCard: {
      background: theme.palette.primary.main,
    },
  })
);

interface IProps {
  images: { src: string; name?: string; link?: string }[];
  interval: number;
}

export default function RollingImg({ images, interval }: IProps) {
  const classes = useStyles();
  const [actImage, setActImage] = useState<number>(images.length === 0 ? -1 : 0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      images.length > 0 && setActImage((actImage + 1) % images.length);
    }, interval);

    return () => clearTimeout(timeoutId);
  }, [images, interval, actImage]);

  useEffect(() => {
    images.length > 0 && setActImage(0);
  }, [images]);

  const image = actImage >= 0 ? images[actImage] : undefined;

  return (
    (image && (
      <>
        <img
          className={classes.banner}
          alt="banner"
          src={image.src}
          width={'100%'}
          height={'100%'}
          style={{
            cursor: image.link && image.link.length > 0 ? 'pointer' : 'default',
          }}
          onClick={() => image.link && image.link.length > 0 && window.open(image.link, '_self')}
        />
        <br />
        <br />
        <Grid container spacing={3}>
          {images
            .map((img, i) => {
              return { ...img, index: i };
            })
            .filter((i) => i.name && i.name.length > 0)
            .map((i) => (
              <Grid key={i.index} item xs={6} sm={4} md={3} lg={2}>
                <div
                  className={`${classes.stepCard} ${i.index === actImage ? classes.actStepCard : ''}`}
                  onClick={() => setActImage(i.index)}
                >
                  <EText color={i.index === actImage ? 'contrast' : 'primary'}>{i.name}</EText>
                </div>
              </Grid>
            ))}
        </Grid>
      </>
    )) || <></>
  );
}
