import React, { useEffect, useState } from 'react';
import { createStyles, InputAdornment, TextField, Theme, withStyles } from '@material-ui/core';
import { nonEmptyValidator, validatorCombiner } from '../Utils/Validators';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  })
)(TextField);

interface IProps {
  label?: string;
  value?: string;
  onChange?: (val: string) => void;
  onBlur?: () => void;
  formatter?: (val: string) => string;
  required?: boolean;
  validator?: (value: string) => boolean;
  invalidText?: string;
  icon?: JSX.Element;
  onValidChange?: (valid: boolean) => void;
}

export default function ETextFiled({
  onChange,
  onBlur,
  formatter,
  value,
  required,
  label,
  validator,
  invalidText,
  icon,
  onValidChange,
}: IProps) {
  const [startValidation, setStartValidation] = useState(false);

  if (required) {
    validator = validatorCombiner([nonEmptyValidator, validator || (() => true)]);
    invalidText = nonEmptyValidator(value || '') ? invalidText : 'Toto pole nesmí být prázdné';
  }

  const isValid = validator === undefined || !startValidation ? true : validator(value || '');

  useEffect(() => {
    onValidChange && onValidChange(validator === undefined || validator(value || ''));
  }, [validator, onValidChange, isValid, value]);

  return (
    <BootstrapInput
      fullWidth
      required={required}
      variant="outlined"
      color={'primary'}
      error={!isValid}
      helperText={isValid ? '' : invalidText}
      label={label}
      value={value}
      onChange={(e) => {
        onChange && onChange((formatter && formatter(e.target.value)) || e.target.value);
        setStartValidation(true);
      }}
      onBlur={() => {
        setStartValidation(true);
        onBlur && onBlur();
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
      }}
    />
  );
}
