import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { ITreeCategory } from '../Types/base';
import { catalogService } from './ApiService';

export const loadCategories = (onLoad: (cats: ITreeCategory[]) => void) => {
  const lsItem = localStorage.getItem('categoriesTree');
  if (lsItem === null || lsItem === 'null') {
    loadCategoriesFromApi(onLoad);
    return false;
  } else {
    onLoad(JSON.parse(lsItem));
    return true;
  }
};

export const loadCategoriesFromApi = (onLoad: (cats: ITreeCategory[]) => void) =>
  catalogService.get('categories/noauth/tree', [], {
    success: (cats: ITreeCategory[]) => {
      onLoad(cats);
      localStorage.setItem('categoriesTree', JSON.stringify(cats));
    },
    error: console.log,
  });

export const getParentCategory = (cat: ICategory, cats: ICategory[]) => {
  let splitId = cat.id.split('.');
  splitId.pop();
  const desiredId = splitId.join('.');
  if (desiredId.length === 0) return undefined;
  return getCategoryById(desiredId, cats);
};

export const getCategoryById = (id: string, cats: ICategory[]) =>
  getCategoryByIdRec(id, {
    description: '',
    id: '',
    subCategories: cats,
    visible: false,
  });

const getCategoryByIdRec = (id: string, root: ICategory): ICategory | undefined => {
  if (root.id === id) return root;
  for (let i = 0; i < root.subCategories.length; i++) {
    const c = getCategoryByIdRec(id, root.subCategories[i]);
    if (c !== undefined) return c;
  }
  return undefined;
};
