import React from 'react';
import { createStyles, makeStyles, Theme, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Roboto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    error: {
      color: theme.palette.primary.main,
      fontSize: '2rem',
    },
    e404: {
      color: theme.palette.primary.main,
      fontSize: '8rem',
      fontWeight: 'bold',
    },
    homeBtn: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      padding: 16,
      borderRadius: 8,
      cursor: 'pointer',
      width: 188,
    },
  })
);

export default function Error404() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container maxWidth="xs" className={classes.root}>
      <br />
      <br />
      <div className={classes.error}>error</div>
      <div className={classes.e404}>404</div>
      <div>
        <p>Je nám líto, ale požadovaná stránka nebyla nalezena.</p>
        <p>Zkontrolujte si prosím, zda máte správně napsanou URL adresu.</p>
        <p>Je možné, že požadovaná stránka byla odstraněna nebo přemístěna na jinou URL adresu.</p>
      </div>
      <br />
      <div className={classes.homeBtn} onClick={() => history.push('/')}>
        Přejít na úvodní stránku
      </div>
      <br />
      <br />
      <div>
        <p>Pokud chyba stále přetrvává, kontaktujte prosím naši technickou podopru.</p>
      </div>
      <br />
      <br />
    </Container>
  );
}
