import React from 'react';
import { createStyles, makeStyles, Theme, Chip } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginRight: 8,
    },
  })
);

interface IProps {
  product: IProduct;
}

export default function ProductPropsSummary({ product }: IProps) {
  const classes = useStyles();

  const props = [
    { name: 'noSweeteners', label: 'Bez umělých sladidel' },
    { name: 'noColoring', label: 'Bez umělých barviv' },
    { name: 'noConservants', label: 'Bez konzervantů' },
    { name: 'isNatural', label: 'Přírodní' },
    { name: 'noSugar', label: 'Bez cukru' },
    { name: 'vegan', label: 'Vegan' },
    { name: 'noGluten', label: 'Bez lepku' },
    { name: 'noLactose', label: 'Bez laktózy' },
    { name: 'goodForPregnancy', label: 'Vhodné při těhotenství' },
    { name: 'whileBreastFeeding', label: 'Vhodné při kojení' },
  ];

  return (
    <>
      {props
        .filter((p) => product[p.name as keyof IProduct] === true)
        .map((p) => (
          <Chip
            key={p.name}
            label={p.label}
            style={{
              backgroundColor: '#84B517',
              color: '#ffffff',
            }}
            className={classes.chip}
          />
        ))}
    </>
  );
}
