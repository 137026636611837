import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { nonEmptyValidator, validatorCombiner } from '../Utils/Validators';
import EText from './EText';

interface IProps {
  label: string;
  value?: string;
  onChange: (newVal: string) => void;
  required?: boolean;
  onValidChange?: (valid: boolean) => void;
}
//
export const phoneNumberValidator = (value: string) => {
  if (value === '') {
    return true;
  }
  const cleanedValue = value.replace(/\s+/g, '');
  if (cleanedValue.startsWith('420') || cleanedValue.startsWith('421')) {
    return /^42[01][\d]{9}$/.test(cleanedValue);
  } else {
    return /^[\d]{10-14}$/.test(cleanedValue);
  }
};

export default function ValidatedPhoneInput({ onChange, value, label, required, onValidChange }: IProps) {
  const [startValidation, setStartValidation] = useState(false);

  const theme = useTheme();

  let validator = phoneNumberValidator;

  if (required) {
    validator = validatorCombiner([nonEmptyValidator, validator || (() => true)]);
  }

  const isValid = !startValidation || validator(value || '');
  const invalidText =
    required && !nonEmptyValidator(value || '') ? 'Toto pole nesmí být prázdné' : 'Zadané pole nemá správný formát';
  useEffect(() => {
    onValidChange &&
      onValidChange((!required || (value?.length || 0) > 0) && (validator === undefined || validator(value || '')));
  }, [validator, onValidChange, isValid, value, required]);

  return (
    <>
      <PhoneInput
        value={value || ''}
        onChange={onChange}
        country="cz"
        preferredCountries={['cz']}
        placeholder="+420 000 000 000"
        inputStyle={{
          borderColor: isValid ? theme.palette.primary.main : '#ff0000',
          width: '100%',
        }}
        containerStyle={{ paddingRight: 12 }}
        specialLabel={label + (required ? ' *' : '')}
        onBlur={() => setStartValidation(true)}
      />
      {!isValid && (
        <EText variant="caption" style={{ color: '#ff0000' }}>
          {invalidText}
        </EText>
      )}
    </>
  );
}
