export const scrollToRefObject = (ref: any) => window.scrollTo(0, ref.current.offsetTop); //TODO ref type

export const round = (x: number, dec: number) => Math.round(x * Math.pow(10, dec)) / Math.pow(10, dec);

export const cutString = (str: string, maxLength: number) =>
  str.length > maxLength + 4 ? str.substring(0, maxLength) + '...' : str + '.';

export const separateThousands = (x: string | number | undefined) =>
  x === undefined ? '' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const nullOrUndefined = (x: any) => x === undefined || x === null;

export const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);

export const formatPhoneNumber = (number: string) =>
  '+' + separateThousands(number.replace(/[ ]+/g, '').replace('+', ''));

export const toCzechTime = (date: Date | string) =>
  new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth(),
    new Date(date).getDate(),
    new Date(date).getHours(),
    new Date(date).getMinutes() - new Date(date).getTimezoneOffset(),
    new Date(date).getSeconds()
  );

export const convertGramsToKg = (grams: number) => Number((grams / 1000).toPrecision(4));
