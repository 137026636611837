import React from 'react';
import EText from './EText';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import env from '../Environments';
import { round, separateThousands } from '../Utils/Common';
import { getPrice } from '../Utils/Product';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { useHistory } from 'react-router-dom';
import { useLayout } from '../Layout/LayoutContext';
import CartButton from './CartButton';
import LabelsContainer from './LabelsContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stockAvailabilityContainer: {
      position: 'absolute',
      bottom: 36,
      width: '100%',
      textAlign: 'center',
    },
    availableText: {
      fontWeight: 'bold',
      color: 'green',
    },
    unavailableText: {
      fontWeight: 'bold',
      color: 'red',
    },
    paper: {
      marginLeft: '10%',
      width: 'calc(80% - 32px)',
      marginBottom: 16,
      height: 'calc(100% - 32px)',
      position: 'relative',
    },
    image: {
      width: '40%',
      marginLeft: '30%',
    },
    cartButton: {
      position: 'absolute',
      bottom: 94,
      left: 'calc(50% - 66px)',
    },
    description: {
      padding: 16,
    },
    unitPrice: {
      fontSize: '0.75rem',
      fontFamily: 'Roboto',
      color: 'grey',
      position: 'absolute',
      bottom: 156,
      width: '100%',
      textAlign: 'center',
    },
    price: {
      width: 'calc(100% - 32px)',
      position: 'absolute',
      left: 8,
      bottom: 172,
    },
    characteristicsText: {
      position: 'absolute',
      left: 8,
      bottom: 12,
    },
  })
);

enum ProductType {
  DEFAULT_PRODUCT = 0,
  ALTERNATIVE_PRODUCT = 1,
}

interface IProps {
  alternativeModalOpen: boolean;
  setAlternativeModalOpen: (val: boolean) => void;
  product: IProduct;
  alternative: IProduct;
  pickupPlaces: PickupPlace[];
}

const AlternativeDialog = ({
  alternativeModalOpen,
  setAlternativeModalOpen,
  product,
  alternative,
  pickupPlaces,
}: IProps) => {
  const layout = useLayout();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );

  return (
    <>
      <Dialog open={alternativeModalOpen} onClose={() => setAlternativeModalOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <Typography variant="h4" align="center">
              Alternativa. {env.fullName} doporučuje.
            </Typography>
            <EText color="secondary" variant="caption" align="center">
              Ušetřete{' '}
              {separateThousands(
                Math.max(
                  round(
                    (1 -
                      getPrice(alternative, layout.homeConfig.todaysSpecial) /
                        (alternative.ammount || 1) /
                        (getPrice(product, layout.homeConfig.todaysSpecial) / (product.ammount || 1))) *
                      100,
                    0
                  ),
                  0
                )
              )}{' '}
              % díky vhodné alternativě nebo získejte kvalitnější produkt
            </EText>
            <IconButton
              style={{ position: 'absolute', top: 12, right: 22 }}
              onClick={() => setAlternativeModalOpen(false)}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {[product, alternative].map((p, productType: ProductType) => {
              const price = getPrice(p, layout.homeConfig.todaysSpecial);
              const unitPrice = price / (p.ammount || 1);
              const originalUnitPrice = getPrice(product, layout.homeConfig.todaysSpecial) / (product.ammount || 1);

              return (
                <Grid item xs={12} md={6} key={p.id}>
                  <div className={classes.paper}>
                    <div style={{ padding: 8 }}>
                      <div style={{ position: 'relative' }}>
                        <LabelsContainer
                          product={productType === ProductType.DEFAULT_PRODUCT ? product : alternative}
                          showBottomDescription={false}
                        >
                          <img
                            src={`${
                              (p.images?.length || 0) > 0 ? (p.images as string[])[0] : '/img/noImage.webp'
                            }?size=512`}
                            alt="product preview"
                            className={classes.image}
                          />
                        </LabelsContainer>

                        <div
                          style={{
                            position: 'absolute',
                            fontFamily: 'Roboto',
                            color: 'white',
                            padding: 8,
                            margin: 0,
                            textAlign: 'center',
                            zIndex: 800,
                            bottom: -16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor:
                              productType === ProductType.DEFAULT_PRODUCT
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main,
                            fontSize: '1.2rem',
                            overflow: 'hidden',
                            borderRadius: 8,
                            width: '50%',
                          }}
                        >
                          {productType === ProductType.DEFAULT_PRODUCT
                            ? 'Produkt z reklamy'
                            : `Produkt o ${separateThousands(
                                Math.max(round((1 - unitPrice / originalUnitPrice) * 100, 0), 0)
                              )} % levnější`}
                        </div>
                      </div>
                      <br />
                      <br />
                      <EText
                        onClick={() =>
                          history.push(
                            `/product/${p.title?.replace(/%/g, '')?.replace(/[ ]/g, '-')}-${p.subtitle
                              ?.replace(/%/g, '')
                              ?.replace(/[ ]/g, '-')}-${p.id}`
                          )
                        }
                        align="center"
                        color="secondary"
                        variant="h6"
                      >
                        {p.title}
                      </EText>
                      <EText align="center" color="primary" variant="subtitle2">
                        {p.subtitle}
                      </EText>
                      <EText className={classes.description} multiline align="center" color="primary" variant="body1">
                        {(p?.activeSubstances || []).join(', ')}
                      </EText>
                      {productType === ProductType.ALTERNATIVE_PRODUCT && (
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'green',
                          }}
                        >
                          <CheckCircle />
                          <EText
                            className={classes.description}
                            style={{ color: 'green' }}
                            multiline
                            align="center"
                            color="primary"
                            variant="body1"
                          >
                            {product.reccomendedAlternative?.description}
                          </EText>
                        </span>
                      )}
                      <br /> <br /> <br /> <br /> <br /> <br />
                      <br /> <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                    <EText className={classes.price} inline align="center" color="primary" variant="h4">
                      {separateThousands(price)} Kč{' '}
                      {(price || 0) < (p.reccomendedPrice || 0) && (
                        <span>
                          <Typography
                            noWrap
                            display="inline"
                            style={{ textDecoration: 'line-through' }}
                            align="center"
                            color="secondary"
                            variant="h6"
                          >
                            {separateThousands(p.reccomendedPrice)} Kč
                          </Typography>
                        </span>
                      )}
                    </EText>
                    <span className={classes.unitPrice}>
                      {separateThousands(round((price || 0) / (p.ammount || 1), 2))} Kč/{p.unit}
                      {productType === ProductType.ALTERNATIVE_PRODUCT && unitPrice < originalUnitPrice && (
                        <>
                          &nbsp;-&nbsp;levnější&nbsp;o&nbsp;
                          {separateThousands(round((1 - unitPrice / originalUnitPrice) * 100, 0))}
                          &nbsp;%
                        </>
                      )}
                    </span>
                    <CartButton
                      isRecursive
                      className={classes.cartButton}
                      product={p}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className={classes.stockAvailabilityContainer}>
                      {(p.stockAmmount || 0) > 0 ||
                      ((p.expiration || '') !== '' && new Date(p.expiration || '').getFullYear() > 2000) ? (
                        <EText className={classes.availableText} style={{ color: 'green' }}>
                          Skladem na e-shopu
                        </EText>
                      ) : (
                        <>
                          <EText className={classes.unavailableText} style={{ color: 'red' }}>
                            Dočasně nedostupné na eshopu
                          </EText>
                        </>
                      )}
                      {env.showReservation && (
                        <>
                          {product.pharmasStock && availablePlaces.length > 0 ? (
                            <EText style={{ fontSize: 12, color: 'green' }}>
                              K rezervaci na {p.pharmasStock} {p.pharmasStock === 1 ? 'lékárně' : 'lékárnách'}
                            </EText>
                          ) : (
                            <EText style={{ fontSize: 12, color: 'green' }}>&nbsp;</EText>
                          )}
                        </>
                      )}
                    </div>
                    <EText inline color="primary" variant="caption" className={classes.characteristicsText}>
                      {p.characteristics}
                    </EText>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AlternativeDialog;
