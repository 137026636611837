import React from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import CommonHeader from './CommonHeader';
import EText from '../../Components/EText';
import { useState } from 'react';
import { Order } from '../../Types/order';
import { useEffect } from 'react';
import { imagesService, ordersService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import { separateThousands } from '../../Utils/Common';
import { ShippingPaymentConfiguration } from '@magistrmartin/eshop-frontend-shared';
import { useHistory } from 'react-router-dom';
import { getKeyExtractorReversedComparer } from '../../Utils/Comparers';
import TotalSavingsPanel from '../FinnishOrder/TotalSavingsPanel';
import { reducers } from '../../Utils/Reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderRoot: {
      cursor: 'pointer',
      borderRadius: 8,
      display: 'flex',
      padding: 32,
      margin: 16,
      fontFamily: 'Roboto',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    orderText: {
      marginRight: '8%',
    },
    flexGrow: {
      flexGrow: 1,
    },
  })
);

export default function MyOrders() {
  const classes = useStyles();
  const [orders, setOrders] = useState<Order[]>([]);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );

  const history = useHistory();
  const layout = useLayout();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      ordersService.get(
        '/noauth/all',
        { userId: user.sub },
        {
          success: (data: Order[]) => setOrders(data.sort(getKeyExtractorReversedComparer('id'))),
          error: () => layout.error('Při načítání objednávek došlo k chybě.'),
        }
      );
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  useEffect(
    () => {
      imagesService.get(
        '/text',
        { file: 'shippingPayment.json', location: 'config' },
        {
          success: setDeliveryPaymentConfig,
          error: () => console.log('Při načítání konfigurací dopravy a platby došlo k chyně'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    (isAuthenticated && (
      <Container maxWidth="lg">
        <CommonHeader selected="orders" />
        <br />
        <br />
        <br />
        <EText variant="h5" align="center">
          Objednávky a faktury
        </EText>
        <br />
        {orders.map((o) => (
          <div key={o.id as number} className={classes.orderRoot} onClick={() => history.push(`/Order/${o.securedId}`)}>
            <div className={classes.orderText}>{o.id}</div>
            <div className={classes.orderText}>{new Date(o.createdDate || '').toLocaleDateString('cs-CZ')}</div>
            <div className={classes.flexGrow}>&nbsp;</div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <div style={{ minWidth: 256, textAlign: 'left' }}>
                <b>
                  {(deliveryPaymentConfig &&
                    //TODO: remove this ignore...
                    //@ts-ignore
                    deliveryPaymentConfig.paymentPrices[o.paymentType].label) ||
                    o.paymentType}
                </b>
              </div>
              <div style={{ minWidth: 328, textAlign: 'right' }}>
                <b>
                  {(deliveryPaymentConfig &&
                    deliveryPaymentConfig.shippingPrices[
                      //TODO: remove this ignore...
                      //@ts-ignore
                      o.deliveryInfo.deliveryType
                    ].label) ||
                    o.deliveryInfo?.deliveryType}
                </b>
              </div>
              <div style={{ minWidth: 104, textAlign: 'right' }}>
                <b>{separateThousands(o.totalPrice)} Kč</b>
              </div>
            </div>
          </div>
        ))}
        <br />
        <br />
        <TotalSavingsPanel
          actualPrice={0}
          expectedPrice={orders.map((o) => o.totalSavings || 0).reduce(reducers.sum, 0)}
        />
        <br />
        <br />
      </Container>
    )) || <></>
  );
}
