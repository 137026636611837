import React, { useEffect, useState } from 'react';
import { Avatar, Checkbox, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Business, LocalShipping } from '@material-ui/icons';
import EText from '../../Components/EText';
import ETextField from '../../Components/ETextField';
import { IProfile } from '@magistrmartin/eshop-frontend-shared';
import { formatters } from '../../Utils/InputFormatters';
import { getRegexValidator } from '../../Utils/Validators';
import 'react-phone-input-2/lib/material.css';
import ValidatedPhoneInput from '../../Components/ValidatedPhoneInput';
import FoxentryEmailInput from '../../Components/FoxentryEmailInput';
import Environment from '../../Environments';
import FoxentryLocationInput from '../../Components/FoxentryLocationInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    verticalCenter: {
      display: 'flex',
      alignItems: 'center',
      height: 56,
    },
    address_subtitle: {
      paddingBlockEnd: 10,
    },
  })
);

interface IProps {
  profile: IProfile;
  setProfile: (profile: IProfile) => void;
  differentDelivery: boolean;
  setDifferentDelivery: (val: boolean) => void;
  buyOnCompany: boolean;
  setBuyOnCompany: (val: boolean) => void;
  hideDeliveryAddress?: boolean;
  setIsValid?: (val: boolean) => void;
}

export default function ContactInfoForm({
  profile,
  setProfile,
  buyOnCompany,
  differentDelivery,
  setBuyOnCompany,
  setDifferentDelivery,
  hideDeliveryAddress,
  setIsValid,
}: IProps) {
  const classes = useStyles();
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

  useEffect(() => {
    setIsValid && setIsValid(invalidInputs.length === 0);
  }, [invalidInputs, setIsValid]);

  const resetFactCompany = () => {
    setProfile({
      ...profile,
      address: { ...profile.address, company: undefined },
      companyId: undefined,
      vat: undefined,
    });
  };

  const getValidityChangeHandler = (key: string) => (valid: boolean) => {
    if (valid && invalidInputs.includes(key)) {
      setInvalidInputs(invalidInputs.filter((i) => i !== key));
    }
    if (!valid && !invalidInputs.includes(key)) {
      setInvalidInputs([...invalidInputs, key]);
    }
  };

  return (
    <Grid container spacing={3}>
      {hideDeliveryAddress === true && <Grid item xs={12} md={3}></Grid>}
      <Grid item xs={12} md={6}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Business />
          </Avatar>
          <EText variant="h5">Fakturační adresa (adresa bydliště)</EText>
          <br />
          <Grid container spacing={3}>
            {/* {Environment.useFoxentry ? (
              <FoxentryNameInput
                value={{ firstName: profile.address?.name, surname: profile.address?.surname }}
                onValidChange={getValidityChangeHandler("name")}
                onChange={(val) =>
                  setProfile({ ...profile, address: { ...profile.address, name: val.firstName, surname: val.surname } })
                }
                required
              />
            ) : (*/}
            <>
              <Grid item xs={12} md={6}>
                <ETextField
                  required
                  value={profile.address?.name || ''}
                  onChange={(val) =>
                    setProfile({
                      ...profile,
                      address: {
                        ...profile.address,
                        name: formatters.TitlesCaseFormatter(val).replace(/[/\\]/g, ''),
                      },
                    })
                  }
                  label="Jméno"
                  onValidChange={getValidityChangeHandler('name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ETextField
                  required
                  value={profile.address?.surname || ''}
                  onChange={(val) =>
                    setProfile({
                      ...profile,
                      address: {
                        ...profile.address,
                        surname: formatters.TitlesCaseFormatter(val).replace(/[/\\]/g, ''),
                      },
                    })
                  }
                  label="Příjmení"
                  onValidChange={getValidityChangeHandler('surname')}
                />
              </Grid>
            </>
            {/* )} */}
            {Environment.useFoxentry ? (
              <FoxentryLocationInput
                value={{
                  street: profile.address?.street,
                  houseNumber: profile.address?.houseNum,
                  zip: profile.address?.zip,
                  city: profile.address?.city,
                }}
                onValidChange={getValidityChangeHandler('location')}
                onChange={(val) =>
                  setProfile({
                    ...profile,
                    address: {
                      ...profile.address,
                      street: val.street,
                      houseNum: val.houseNumber,
                      zip: val.zip,
                      city: val.city,
                    },
                  })
                }
                required
                intellisense
              />
            ) : (
              <>
                <Grid item xs={12} md={9} style={{ paddingBlockEnd: 0 }}>
                  <ETextField
                    required
                    value={profile.address?.street || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        address: {
                          ...profile.address,
                          street: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                        },
                      })
                    }
                    label="Ulice"
                    onValidChange={getValidityChangeHandler('street')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ETextField
                    required
                    value={profile.address?.houseNum || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        address: { ...profile.address, houseNum: val },
                      })
                    }
                    label="ČP"
                    onValidChange={getValidityChangeHandler('houseNum')}
                  />
                </Grid>
                <Grid item xs={12} md={9} style={{ paddingBlockStart: 0 }}>
                  <EText
                    asDiv
                    fontSize="12px"
                    style={{ color: 'green', paddingInlineStart: 14 }}
                    className={classes.address_subtitle}
                  >
                    V případě menší vesnice, kde nejsou rozlišeny ulice vyplňte do pole ulice přímo název obce.
                  </EText>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}>
                  <ETextField
                    required
                    value={profile.address?.zip || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        address: { ...profile.address, zip: val },
                      })
                    }
                    label="PSČ"
                    validator={getRegexValidator(RegExp('^[\\d]{3} [\\d]{2}$'))}
                    invalidText="PSČ nemá správný formát, očekávaný formát je 000 00"
                    formatter={formatters.ZIPFormater}
                    onValidChange={getValidityChangeHandler('zip')}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <ETextField
                    required
                    value={profile.address?.city || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        address: {
                          ...profile.address,
                          city: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                        },
                      })
                    }
                    label="Město"
                    onValidChange={getValidityChangeHandler('city')}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              {Environment.useFoxentry ? (
                <FoxentryEmailInput
                  required
                  value={profile.email || ''}
                  onChange={(val) => setProfile({ ...profile, email: val })}
                  label="Email"
                  onValidChange={getValidityChangeHandler('email')}
                />
              ) : (
                <ETextField
                  required
                  value={profile.email || ''}
                  onChange={(val) => setProfile({ ...profile, email: val })}
                  validator={getRegexValidator(
                    RegExp(
                      `^(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$`
                    )
                  )}
                  formatter={formatters.StripFormatter}
                  invalidText="Email nemá správný formát"
                  label="Email"
                  onValidChange={getValidityChangeHandler('email')}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* {Environment.useFoxentry ? (
                <FoxentryPhoneInput
                  label="Telefon"
                  onChange={(val: string) => setProfile({ ...profile, address: { ...profile.address, phone: val } })}
                  value={profile.address?.phone}
                  onValidChange={getValidityChangeHandler("phone")}
                  required
                />
              ) : ( */}
              <ValidatedPhoneInput
                value={profile.address?.phone}
                onChange={(val: string) =>
                  setProfile({
                    ...profile,
                    address: { ...profile.address, phone: val },
                  })
                }
                required
                label="Telefon"
                onValidChange={getValidityChangeHandler('phone')}
              />
              {/* )} */}
            </Grid>
            {hideDeliveryAddress !== true && (
              <>
                <Grid item xs={12}>
                  <EText variant="h6">Kontakty pro kurýra (nepovinné)</EText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ETextField
                    value={profile.deliveryAddress?.company || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        deliveryAddress: {
                          ...profile.deliveryAddress,
                          company: val,
                        },
                      })
                    }
                    label="Firma (dodací místo)"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ValidatedPhoneInput
                    value={profile.deliveryAddress?.phone || ''}
                    onChange={(val: string) =>
                      setProfile({
                        ...profile,
                        deliveryAddress: {
                          ...profile.deliveryAddress,
                          phone: val,
                        },
                      })
                    }
                    label="Telefon pro kurýra (pokud se liší)"
                    onValidChange={getValidityChangeHandler('phoneDelivery')}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div className={classes.verticalCenter}>
                <Checkbox
                  checked={buyOnCompany}
                  onChange={() => {
                    resetFactCompany();
                    setBuyOnCompany(!buyOnCompany);
                  }}
                  color="primary"
                />{' '}
                <EText inline>Kupuji na firmu</EText>
              </div>
            </Grid>
            {buyOnCompany && (
              <>
                <Grid item xs={12}>
                  <ETextField
                    value={profile.address?.company || ''}
                    onChange={(val) =>
                      setProfile({
                        ...profile,
                        address: { ...profile.address, company: val },
                      })
                    }
                    label="Firma"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ETextField
                    value={profile.companyId || ''}
                    onChange={(val) => setProfile({ ...profile, companyId: val })}
                    label="IČ"
                    formatter={formatters.CompIdFormater}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ETextField
                    value={profile.vat || ''}
                    onChange={(val) => setProfile({ ...profile, vat: val })}
                    label="DIČ"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Grid>
      {hideDeliveryAddress !== true && (
        <Grid item xs={12} md={6}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LocalShipping />
            </Avatar>
            <EText variant="h5">Dodací adresa</EText>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.verticalCenter}>
                  <Checkbox
                    checked={differentDelivery}
                    onChange={() => {
                      setDifferentDelivery(!differentDelivery);
                    }}
                    color="primary"
                  />{' '}
                  <EText inline>Dodací adresa se liší od fakturační</EText>
                </div>
              </Grid>
              {differentDelivery && (
                <>
                  <Grid item xs={12} md={6}>
                    <ETextField
                      value={profile.deliveryAddress?.name || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            name: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                          },
                        })
                      }
                      label="Jméno"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ETextField
                      value={profile.deliveryAddress?.surname || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            surname: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                          },
                        })
                      }
                      label="Příjmení"
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ETextField
                      value={profile.deliveryAddress?.street || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            street: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                          },
                        })
                      }
                      label="Ulice"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ETextField
                      value={profile.deliveryAddress?.houseNum || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            houseNum: val,
                          },
                        })
                      }
                      label="ČP"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ETextField
                      label="PSČ"
                      value={profile.deliveryAddress?.zip || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            zip: val,
                          },
                        })
                      }
                      formatter={formatters.ZIPFormater}
                      validator={getRegexValidator(RegExp('^[\\d]{3} [\\d]{2}$'))}
                      invalidText="PSČ nemá správný formát, očekávaný formát je 000 00"
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <ETextField
                      value={profile.deliveryAddress?.city || ''}
                      onChange={(val) =>
                        setProfile({
                          ...profile,
                          deliveryAddress: {
                            ...profile.deliveryAddress,
                            city: val.length > 0 ? val[0].toUpperCase() + val.substring(1) : val,
                          },
                        })
                      }
                      label="Město"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>
      )}
    </Grid>
  );
}
