import React, { useEffect, useState } from 'react';
import { catalogService } from '../../Utils/ApiService';
import EText from '../../Components/EText';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

interface IProps {
  searchedText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
    categorySearchRes: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      width: 'calc(100% - 16px)',
      display: 'flex',
      paddingTop: 16,
      paddingBottom: 16,
      margin: 8,
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    categorySearchResPath: {
      fontSize: '0.6rem',
      paddingTop: 0,
      marginTop: -8,
      width: 'calc(100% - 32px)',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      overflow: 'hidden',
    },
  })
);

const SearchedCategories = ({ searchedText }: IProps) => {
  const [searchedCats, setSearchedCats] = useState<{ [index: string]: string }>({});

  const [showAllCats, setShowAllCats] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    catalogService.get(
      '/categories/noauth/search',
      { searchText: searchedText },
      {
        success: (cats) => {
          setSearchedCats(cats);
        },
        error: console.log,
      }
    );
  }, [searchedText]);
  return (
    <>
      {Object.keys(searchedCats).length > 0 && (
        <>
          <br />
          <EText variant="h6">Nalezené kategorie:</EText>
          <Grid container>
            {' '}
            {Object.keys(searchedCats)
              .filter((_, i) => i < 12 || showAllCats)
              .map((cat) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={cat}>
                  <div
                    onClick={() =>
                      history.push(
                        `/offer/category/${searchedCats[cat]
                          .split('/')
                          .pop()
                          ?.replace(/%/g, '')
                          ?.replace(/[ ]/g, '-')}-${cat}`
                      )
                    }
                    className={classes.categorySearchRes}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 16px)',
                        textAlign: 'center',
                      }}
                    >
                      {searchedCats[cat].split('/').pop()}
                    </div>
                    <br />
                    <div className={classes.categorySearchResPath}>{searchedCats[cat]}</div>
                  </div>
                </Grid>
              ))}
          </Grid>
          {Object.keys(searchedCats).length > 12 && (
            <span onClick={() => setShowAllCats(!showAllCats)} className={classes.pointer}>
              <EText variant="body1" color="secondary" align="center">
                {!showAllCats && (
                  <>
                    Zobrazit všechny nalezené kategorie
                    <br />
                  </>
                )}
                {showAllCats ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
              </EText>
            </span>
          )}
          <br />
          <br />
        </>
      )}
    </>
  );
};
export default SearchedCategories;
