import React from 'react';
import { createStyles, makeStyles, Theme, Grid, Chip, Tooltip } from '@material-ui/core';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { qualityLabels } from '../Utils/QualityLabels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      width: '90%',
      marginLeft: '5%',
      marginBottom: 8,
    },
  })
);

interface IProps {
  product: IProduct;
}

export default function QualityLabels({ product }: IProps) {
  const classes = useStyles();

  return (
    <Grid container>
      <>
        {qualityLabels.map((p) => (
          <Grid item xs={12} md={6} key={p.name}>
            <Tooltip title={p.tooltip || ''}>
              <Chip
                label={p.label}
                style={{
                  backgroundColor: product[p.name as keyof IProduct] === true ? '#84B517' : 'default',
                  color: product[p.name as keyof IProduct] === true ? '#ffffff' : 'default',
                }}
                className={classes.chip}
              />
            </Tooltip>
          </Grid>
        ))}
      </>
    </Grid>
  );
}
