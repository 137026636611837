import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { discountLabelColors, getLabelName, getLeftDiscounts, getRightDiscounts } from '../Utils/Labels';
import { faGift, faShoppingCart, faStopwatch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useLayout } from '../Layout/LayoutContext';
import { IDailySpecial } from '../Types/base';
import LabelsStack, { LabelsSize } from './LabelsStack';
import { PRODUCT_PREVIEW_BORDER_RADIUS } from '../Utils/Constants';
import { getProductExpirationIfAny, IProduct } from '@magistrmartin/eshop-frontend-shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerContainer: {
      position: 'relative',
    },
    leftLabels: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 3,
      borderTopLeftRadius: PRODUCT_PREVIEW_BORDER_RADIUS,
      overflow: 'hidden',
    },
    rightLabels: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      zIndex: 3,
      borderTopRightRadius: PRODUCT_PREVIEW_BORDER_RADIUS,
      overflow: 'hidden',
    },

    longDescriptionLabel: {
      padding: '10px',
      fontSize: '0.8rem',
      fontFamily: 'Roboto',
      color: 'white',
      textAlign: 'center',
    },
  })
);

interface IProps {
  product: IProduct;
  showBottomDescription: boolean;
  children: React.ReactNode;
  labelsSize?: LabelsSize;
}

export interface ILabel {
  icon: IconDefinition | undefined; //TOOD: ikona u giftu faGift
  shortDescription: string;
  longDescription: string | undefined;
  backgroundColor: string;
}

const createExpirationLabel = (expirationDate: Date | string) => ({
  shortDescription: new Date(expirationDate || '').toLocaleDateString('cs-cz'),
  longDescription: `Výhodnější cena produktu s EXP ${new Date(expirationDate || '').toLocaleDateString('cs-cz')}`,
  backgroundColor: 'black',
  icon: faStopwatch,
});

const getLeftLabels = (product: IProduct, todaysSpecials: IDailySpecial[]): ILabel[] => {
  const leftDiscounts = getLeftDiscounts(product, todaysSpecials);
  const leftLabels: ILabel[] = leftDiscounts.map((discount) => ({
    shortDescription: getLabelName(discount),
    longDescription: discount.remark,
    icon: discount.type === 'PRESENT' ? faGift : undefined, //TODO: move to separate function, if there will be multiple discounts with icon.
    backgroundColor: discountLabelColors[discount.type],
  }));
  if ((product.maxCartAmount || 0) > 0) {
    leftLabels.push({
      shortDescription: `Max ${product.maxCartAmount} ks`,
      longDescription: `Maximální množství tohoto produktu na košík: ${product.maxCartAmount} ks`,
      backgroundColor: '#636363',
      icon: faShoppingCart,
    });
  }

  const prodExp = getProductExpirationIfAny(product);
  if (prodExp) leftLabels.push(createExpirationLabel(prodExp));

  return leftLabels;
};

const getRightLabels = (product: IProduct, todaysSpecials: IDailySpecial[]): ILabel[] => {
  const rightDiscounts = getRightDiscounts(product, todaysSpecials);
  return rightDiscounts.map((discount) => ({
    shortDescription: getLabelName(discount),
    longDescription: discount.remark,
    icon: undefined,
    backgroundColor: discountLabelColors[discount.type],
  }));
};

const LabelsContainer = ({ product, showBottomDescription, children, labelsSize = 'md' }: IProps) => {
  const classes = useStyles();
  const layout = useLayout();

  const leftLabels = getLeftLabels(product, layout.homeConfig.todaysSpecial);
  const rightLabels = getRightLabels(product, layout.homeConfig.todaysSpecial);
  return (
    <>
      <div className={classes.outerContainer}>
        <span className={classes.leftLabels}>
          <LabelsStack labels={leftLabels} labelsSize={labelsSize} />
        </span>
        <span className={classes.rightLabels}>
          <LabelsStack labels={rightLabels} labelsSize={labelsSize} />
        </span>
        {children}
        {showBottomDescription &&
          leftLabels.concat(rightLabels).map(
            (label) =>
              label.longDescription && (
                <div className={classes.longDescriptionLabel} style={{ backgroundColor: label.backgroundColor }}>
                  {label.longDescription}
                </div>
              )
          )}
      </div>
    </>
  );
};

export default LabelsContainer;
