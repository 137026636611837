import React, { useEffect } from 'react';
import ETextFiled from './ETextField';
import { formatters } from '../Utils/InputFormatters';
import { statisticsService } from '../Utils/ApiService';
import { Autorenew, CheckCircle, Error } from '@material-ui/icons';
import { Grid, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import EText from './EText';
import { getRegexValidator } from '../Utils/Validators';

type Location = {
  street?: string;
  city?: string;
  houseNumber?: string;
  zip?: string;
};

interface IProps {
  value: Location;
  onChange?: (val: Location) => void;
  onValidChange?: (valid: boolean) => void;
  required?: boolean;
  validated?: boolean;
  intellisense?: boolean;
}

const useStyles = makeStyles((theme) => ({
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export default function FoxentryLocationInput({
  onChange,
  value,
  required,
  onValidChange,
  validated,
  intellisense,
}: IProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [foxentryStatus, setFoxentryStatus] = React.useState<
    'none' | 'inProgress' | 'valid' | 'invalid' | 'corrected' | 'error'
  >(validated ? 'none' : 'valid');
  const [foxentrySuggestions, setFoxentrySuggestions] = React.useState<Location[]>([]);
  const [lastValidatedValue, setLastValidatedValue] = React.useState<Location>({});
  const [visualValidationStarted, setVisualValidationStarted] = React.useState<string[]>([]);

  const zipValidator = (value: Location) => getRegexValidator(RegExp('^[\\d]{3} [\\d]{2}$'))(value.zip || '');

  const isValid =
    (!required ||
      ((value.street?.length || 0) > 0 &&
        (value.city?.length || 0) > 0 &&
        (value.zip?.length || 0) > 0 &&
        (value.houseNumber?.length || 0) > 0)) &&
    (foxentryStatus === 'valid' || foxentryStatus === 'corrected' || foxentryStatus === 'inProgress');

  useEffect(() => {
    onValidChange && onValidChange(foxentryStatus !== 'inProgress' && isValid && zipValidator(value));
  }, [foxentryStatus, onValidChange, isValid, value]);

  const foxentryValidate = () => {
    if (!validated) return;
    if (!value.city || !value.street || !value.zip || !value.houseNumber) return;
    if (!zipValidator(value)) return;
    if (
      foxentryStatus === 'inProgress' ||
      (lastValidatedValue.city === value.city &&
        lastValidatedValue.street === value.street &&
        lastValidatedValue.zip === value.zip &&
        lastValidatedValue.houseNumber === value.houseNumber)
    )
      return;
    setFoxentryStatus('inProgress');
    setLastValidatedValue(value);
    statisticsService.get('/foxentry/validateLocation', value, {
      success: (data) => {
        if (data.correction) {
          onChange &&
            onChange({
              ...data.correction,
              zip: formatters.ZIPFormater(data.correction.zip),
            });
          setFoxentryStatus('corrected');
        } else {
          setFoxentryStatus(data.isValid ? 'valid' : 'invalid');
        }
        setFoxentrySuggestions(data.suggestions);
      },
      error: () => setFoxentryStatus('error'),
    });
  };

  const icon =
    validated && foxentryStatus === 'inProgress' ? (
      <Tooltip title="Probíhá validace adresy...">
        <Autorenew className={classes.rotateIcon} color="action" />
      </Tooltip>
    ) : validated && (foxentryStatus === 'valid' || foxentryStatus === 'corrected') ? (
      <Tooltip title="Adresa byla úspěšně ověřena">
        <CheckCircle color="primary" />
      </Tooltip>
    ) : validated && foxentryStatus === 'invalid' ? (
      <Tooltip title="Zadali jste neplatnou adresu">
        <Error color="error" />
      </Tooltip>
    ) : validated && foxentryStatus === 'error' ? (
      <Tooltip title="Při validaci adresy došlo k chybě, zkuste to prosím později">
        <Error color="error" />
      </Tooltip>
    ) : undefined;

  const invalidText =
    validated && foxentryStatus === 'error'
      ? 'Validace adresy skončila s chybou'
      : validated && foxentryStatus === 'invalid'
        ? 'Zadali jste neplatnou adresu'
        : '';

  return (
    <>
      <Grid item xs={12} md={9}>
        <ETextFiled
          label="Ulice"
          value={value.street || ''}
          onChange={(s) => onChange && onChange({ ...value, street: s })}
          onBlur={() => {
            setVisualValidationStarted([...new Set([...visualValidationStarted, 'street'])]);
            foxentryValidate();
          }}
          required={required}
          validator={() => visualValidationStarted.length < 4 || isValid}
          invalidText={invalidText}
          icon={icon}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ETextFiled
          label="ČP"
          value={value.houseNumber || ''}
          onChange={(hn) => onChange && onChange({ ...value, houseNumber: hn })}
          onBlur={() => {
            setVisualValidationStarted([...new Set([...visualValidationStarted, 'houseNumber'])]);
            foxentryValidate();
          }}
          required={required}
          validator={() => visualValidationStarted.length < 4 || isValid}
          invalidText={invalidText}
          icon={icon}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <ETextFiled
          label="PSČ"
          value={value.zip || ''}
          onChange={(z) => onChange && onChange({ ...value, zip: z })}
          onBlur={() => {
            setVisualValidationStarted([...new Set([...visualValidationStarted, 'zip'])]);
            foxentryValidate();
          }}
          required={required}
          validator={() => visualValidationStarted.length < 4 || (isValid && zipValidator(value))}
          invalidText={zipValidator(value) ? invalidText : 'PSČ nemá správný formát, očekávaný formát je 000 00'}
          formatter={formatters.ZIPFormater}
          icon={icon}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <ETextFiled
          label="Město"
          value={value.city || ''}
          onChange={(c) => onChange && onChange({ ...value, city: c })}
          onBlur={() => {
            setVisualValidationStarted([...new Set([...visualValidationStarted, 'city'])]);
            foxentryValidate();
          }}
          required={required}
          validator={() => visualValidationStarted.length < 4 || isValid}
          invalidText={invalidText}
          icon={icon}
        />
      </Grid>
      {foxentryStatus === 'corrected' &&
      (lastValidatedValue.city !== value.city ||
        lastValidatedValue.street !== value.street ||
        lastValidatedValue.zip !== value.zip ||
        lastValidatedValue.houseNumber !== value.houseNumber) ? (
        <Typography style={{ color: theme.palette.warning.main }} variant="caption">
          Váše jméno adresa byla automaticky opravena. Zadali jste: {lastValidatedValue.street}{' '}
          {lastValidatedValue.houseNumber}, {lastValidatedValue.zip} {lastValidatedValue.city}. Prosím zkontrolujte, že
          je navržená oprava správná.
        </Typography>
      ) : null}
      {(foxentrySuggestions.length && (
        <Grid item xs={12}>
          <EText variant="caption">Měli jste na mysli?</EText>
          {foxentrySuggestions.map((suggestion, index) => (
            <React.Fragment key={index}>
              <br />
              <EText
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  onChange && onChange(suggestion);
                  setFoxentrySuggestions([]);
                  setFoxentryStatus('valid');
                  setLastValidatedValue(suggestion);
                }}
              >
                {lastValidatedValue.street} {lastValidatedValue.houseNumber}, {lastValidatedValue.zip}{' '}
                {lastValidatedValue.city}
              </EText>
            </React.Fragment>
          ))}
        </Grid>
      )) || <></>}
    </>
  );
}
