export const qualityLabels = [
  {
    name: 'noSweeteners',
    label: 'Bez umělých sladidel',
    tooltip:
      'Mezi umělá sladidla řadíme acesulfam K (E950), aspartam (E951), cyklamáty (E952), sacharin (E954), soli aspartamu/acesulfamu (E962). Další složky z této skupiny takto neoznačujeme.',
  },
  { name: 'noColoring', label: 'Bez umělých barviv' },
  { name: 'noConservants', label: 'Bez konzervantů' },
  { name: 'isNatural', label: 'Přírodní' },
  { name: 'noSugar', label: 'Bez cukru' },
  { name: 'isVegan', label: 'Vegan' },
  { name: 'noGluten', label: 'Bez lepku' },
  { name: 'noLactose', label: 'Bez laktózy' },
  { name: 'whilePregnancy', label: 'V těhotenství' },
  { name: 'whileBreastFeeding', label: 'Vhodné při kojení' },
];
