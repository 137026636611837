import React from 'react';
import EText from './EText';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const responsiveBreakpoint = 'sm';
const itemSeparatorBorder = '1px solid #C93666';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    panelItem: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: 'inherit',
      [theme.breakpoints.down(responsiveBreakpoint)]: {
        flexDirection: 'column',
        gap: '15px',
      },
      padding: '20px 0',
    },
    descriptionBlock: {
      padding: 10,
      flexBasis: '50%',
    },
    iconBlock: {
      display: 'flex',
      paddingRight: 20,
      paddingLeft: 20,
    },
    footBlock: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      paddingLeft: 20,
      justifyContent: 'left',
      alignItems: 'center',
      backgroundColor: '#692E66',
      fontSize: '1rem',
      paddingInlineStart: 20,
      paddingInlineEnd: 20,
    },

    footSecondBlock: {
      height: 'fit-content',
      flexGrow: 1,
      padding: '15px',
      textAlign: 'center',
    },
    deliveryFooter: {
      display: 'flex',
      flexWrap: 'wrap',
      borderTop: '1px solid #C93666',
      borderRadius: 12,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      alignContent: 'center',
      justifyContent: 'center',
    },
    priceBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
      textWrap: 'nowrap',
      flexBasis: '20%',
    },
    mmPlusAlterContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '0.8rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: '#C93666',
      color: '#FFFFFF',
      borderTop: '1px solid #C93666',
    },

    deliveryBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 8,
      border: itemSeparatorBorder,
      borderTop: 'none',
      borderBottom: 'none',
      [theme.breakpoints.down(responsiveBreakpoint)]: {
        border: itemSeparatorBorder,
        borderLeft: 'none',
        borderRight: 'none',
      },
      padding: '10px',
      flexBasis: '30%',
    },
    text: {
      fontSize: '1rem',
      margin: 0,
      paddingLeft: 60,
      paddingRight: 60,
    },
    mmPlusIcon: {
      [theme.breakpoints.down(responsiveBreakpoint)]: {
        display: 'none',
      },
    },
  })
);

interface ICardProps {
  iconUrl: string;
  description: string;
  label: string;
  price: string;
  timeEstimate?: string;
  paymentText?: string[];
  priceAddon?: string;
  mmPlusText?: string;
  captionText?: string;
  imgPath?: string;
  imgFooterPath?: string[][];
  isPayment?: boolean;
}

function DeliveryPaymentCard({
  iconUrl,
  description,
  mmPlusText,
  priceAddon,
  label,
  timeEstimate,
  paymentText,
  price,
  captionText,
  imgPath,
  imgFooterPath,
  isPayment,
}: ICardProps) {
  const classes = useStyles();

  return (
    <div className={`${classes.panelItemContainer}`}>
      <div className={classes.panelItem}>
        <div className={classes.iconBlock}>
          <img src={iconUrl} alt="logo" width={86} />
        </div>
        <div className={classes.descriptionBlock}>
          <EText style={{ fontSize: '1.2rem' }} variant="h6" color="primary">
            {label}
          </EText>
          <EText
            asDiv
            style={{ letterSpacing: 0.5 }}
            variant="caption"
            multiline
            align="justify"
            fontSize="1rem"
            lineHeight="1.2"
          >
            {description}
          </EText>
        </div>
        <div className={classes.deliveryBlock}>
          <img src={imgPath} alt="clock" width="36px" />
          <EText asDiv variant="h6" align="center">
            {captionText}
          </EText>
          <EText asDiv variant="caption" align="center">
            {timeEstimate && <p className={classes.text}>{timeEstimate}</p>}
            {paymentText &&
              paymentText.map((deliveryDescription) => <p className={classes.text}>{deliveryDescription}</p>)}
          </EText>
        </div>
        <div className={classes.priceBlock}>
          <EText variant="h4" color="primary" align="center" bold>
            {price}
          </EText>
          {priceAddon && (
            <EText variant="caption" align="center" asDiv bold>
              {priceAddon}
            </EText>
          )}
        </div>
      </div>
      {mmPlusText && (
        <div className={classes.mmPlusAlterContainer}>
          <div className={classes.footBlock}>
            <img
              className={classes.mmPlusIcon}
              src="https://api.magistrmartin.cz/images/noauth/image/images/order_foot.svg"
              alt="order icon"
              width="24px "
            />

            <p>KLUB MAGISTR MARTIN</p>
          </div>
          <div className={classes.footSecondBlock}>{mmPlusText}</div>
        </div>
      )}
      {isPayment && imgFooterPath && (
        <div className={classes.deliveryFooter}>
          {imgFooterPath.map((imgInfo, index) => (
            <img
              style={{ alignSelf: 'center' }}
              src={imgInfo[0]}
              width={imgInfo[1]}
              height={imgInfo[2]}
              alt={`Logo banky ${index}`}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default DeliveryPaymentCard;
