import React, { useEffect, useState } from 'react';
import { Container, createStyles, Hidden, makeStyles, SvgIcon, Theme, useTheme } from '@material-ui/core';
import EText from '../Components/EText';
import Menu from './Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../Components/SearchBar';
import { ReactComponent as HomeDeliveryIcon } from '../Data/icons/homeDelivery.svg';
import { ReactComponent as PersonalPickupIcon } from '../Data/icons/personalPickup.svg';
import { ReactComponent as DeliveryIcon } from '../Data/icons/delivery.svg';
import { useLayout } from './LayoutContext';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import MobileMenu from './MobileMenu';
import { webStructureService } from '../Utils/ApiService';
import { Alert } from '@material-ui/lab';
import Environment from '../Environments';
import BlinkingDot from '../Components/BlinkingDot';
import ShoppingCartButton from '../Components/ShoppingCartButton';
import HeaderButtonsStack from '../Components/HeaderButtonsStack';
import useCart, { CartState } from '../Stores/cartStore';

const logosHeight = '64px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topPanel: {
      backgroundColor: '#F6F8FB',
      padding: '5px',
    },
    searchPanel: {
      paddingTop: '12px',
      paddingBottom: '12px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    contactContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '15px',
      '& svg': {
        marginRight: '5px',
      },
    },
    logo: {
      maxHeight: logosHeight,
      boxSizing: 'border-box',
      cursor: 'pointer',
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
    logoMock: {
      display: 'inline-block',
      height: 64,
    },
    leafletLogo: {
      boxSizing: 'border-box',
      maxHeight: logosHeight,
      objectFit: 'contain',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
    searchPanelRight: {
      [theme.breakpoints.up('md')]: {
        // marginLeft: "auto",
        width: 'calc(100vw - 564px)',
        float: 'right',
      },
      [theme.breakpoints.up('lg')]: {
        width: 718,
      },
    },

    loginButton: {
      textAlign: 'center',
      float: 'right',
      paddingTop: 8,
      marginLeft: 22,
      cursor: 'pointer',
    },
    deliveryPanel: {
      padding: '10px 0',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    deliveryIcon: {
      fontSize: '30px',
      paddingRight: '5px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    notification: {
      justifyContent: 'center',
      marginBottom: 4,
    },
    topPanelContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '20px',
    },
  })
);

const iconViewBox = '0 0 16 16'; // same as .svg data

interface ITopNotification {
  text?: string;
  color?: 'success' | 'info' | 'warning' | 'error';
  validFrom?: Date;
  validTo?: Date;
}

interface IProps {
  hideHeader: boolean;
}

export default function Header({ hideHeader }: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const layout = useLayout();
  const itemsInShoppingCart = useCart((state: CartState) => state.getContentSize());
  const priceInShoppingCart = useCart((state: CartState) => state.getCartPrice(layout.homeConfig.todaysSpecial));

  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const history = useHistory();
  const [notification, setNotification] = useState<ITopNotification>({});
  const [headerPanel, setHeaderPanel] = useState<{ [key: string]: string }>({});

  const validLeaflet = layout.homeConfig.leaflets?.filter(
    (l) =>
      (l.validFrom === undefined || l.validFrom === null || new Date(l.validFrom).getDate() <= new Date().getDate()) &&
      (l.validTo === undefined || l.validTo === null || new Date(l.validTo).getDate() >= new Date().getDate())
  )[0];

  useEffect(() => {
    webStructureService.get(
      '/notifications/top',
      { domain: Environment.domain },
      {
        success: (data: ITopNotification | null) => data && setNotification(data),
        error: console.log,
      }
    );
  }, []);

  useEffect(
    () => {
      webStructureService.get(
        '/header/panel',
        { domain: Environment.domain },
        {
          success: setHeaderPanel,
          error: console.log,
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {notification.text && notification.text.length > 0 && (
        <Alert variant="filled" severity={notification.color} className={classes.notification}>
          {notification.text}
        </Alert>
      )}
      {(
        <div style={{ position: 'relative' }}>
          <div className={classes.topPanel}>
            <Container maxWidth="lg">
              <div className={classes.topPanelContent}>
                <div className={classes.contactContainer}>
                  <EText variant="body1" inline>
                    <FontAwesomeIcon icon={faEnvelope} color={theme.palette.secondary.main} />
                    {Environment.email}
                  </EText>
                  <EText variant="body1" inline>
                    <FontAwesomeIcon icon={faPhoneAlt} color={theme.palette.secondary.main} />
                    {Environment.phoneAndHours}
                  </EText>
                </div>
                <Hidden smDown>{!hideHeader && <HeaderButtonsStack direction="row" />}</Hidden>
              </div>
            </Container>
          </div>

          <Container maxWidth="lg">
            <div className={classes.searchPanel}>
              <img
                src={Environment.logoPath}
                onClick={() => history.push('/')}
                alt="mgr martin logo"
                className={classes.logo}
              />
              {!hideHeader && (
                <img
                  src="/img/leafletLogo.webp"
                  onClick={() => validLeaflet && window.open(validLeaflet.file, '_blank')}
                  alt="leaflet logo"
                  className={classes.leafletLogo}
                />
              )}
              {!hideHeader && (
                <>
                  <div className={classes.searchPanelRight}>
                    <Hidden mdUp>
                      <MobileMenu />
                    </Hidden>
                    <SearchBar
                      label="Zadejte název produktu, kategorii nebo značku"
                      search={(txt) => txt && txt.length > 0 && history.push(`/offer/search/${txt}`)}
                    />
                    <ShoppingCartButton
                      itemsInShoppingCart={itemsInShoppingCart}
                      priceInShoppingCart={priceInShoppingCart}
                    />
                    <Hidden smDown>
                      <div className={classes.loginButton}>
                        {(isAuthenticated && (
                          <span onClick={() => history.push('/MyOrders')}>
                            <FontAwesomeIcon size="lg" color={theme.palette.secondary.main} icon={faUser} />
                            <br />
                            <EText variant="button">Můj účet</EText>
                          </span>
                        )) || (
                          <span onClick={() => loginWithRedirect()}>
                            <FontAwesomeIcon size="lg" color={theme.palette.secondary.main} icon={faUser} />
                            <br />
                            <EText variant="button">Přihlásit se</EText>
                          </span>
                        )}
                      </div>
                    </Hidden>
                  </div>
                </>
              )}
            </div>
            {!hideHeader && (
              <>
                <Hidden smDown>
                  <Menu />
                </Hidden>
                <Hidden smDown>
                  <div className={classes.deliveryPanel} onClick={() => history.push('/DeliveryAndPayment')}>
                    <BlinkingDot />
                    <span className={classes.flex}>
                      <SvgIcon
                        component={PersonalPickupIcon}
                        viewBox={iconViewBox}
                        color="secondary"
                        className={classes.deliveryIcon}
                      />
                      <Hidden mdDown>
                        <EText variant="subtitle1" onClick={() => console.log('cl')}>
                          {(headerPanel['pickup'] || '').replace(/ /g, '\u00A0')}
                        </EText>
                      </Hidden>
                      <Hidden lgUp>
                        <EText variant="subtitle1">
                          {(headerPanel['pickup'] || '').replace('od odeslání', '').replace(/ /g, '\u00A0')}
                        </EText>
                      </Hidden>
                    </span>
                    <span className={classes.flex}>
                      <SvgIcon
                        component={HomeDeliveryIcon}
                        viewBox={iconViewBox}
                        color="secondary"
                        className={classes.deliveryIcon}
                      />
                      <Hidden mdDown>
                        <EText variant="subtitle1">{(headerPanel['home'] || '').replace(/ /g, '\u00A0')}</EText>
                      </Hidden>
                      <Hidden lgUp>
                        <EText variant="subtitle1">
                          {(headerPanel['home'] || '').replace('od odeslání', '').replace(/ /g, '\u00A0')}
                        </EText>
                      </Hidden>
                    </span>
                    <span className={classes.flex}>
                      <SvgIcon
                        component={DeliveryIcon}
                        viewBox={iconViewBox}
                        color="secondary"
                        className={classes.deliveryIcon}
                      />
                      <EText variant="subtitle1">{(headerPanel['delivery'] || '').replace(/ /g, '\u00A0')}</EText>
                    </span>
                  </div>
                  <br />
                </Hidden>
              </>
            )}
          </Container>
        </div>
      ) || <br />}
    </>
  );
}
