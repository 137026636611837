import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import EText from './EText';
interface ILink {
  category?: number;
  link?: string;
  linkOrder?: number;
  name?: string;
  id?: number;
}

export interface ILinksCategory {
  categoryOrder?: number;
  description?: string;
  id?: number;
  footerLinksList?: ILink[];
}

interface IProps {
  links: ILinksCategory[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const FooterLinks = ({ links }: IProps) => {
  const classes = useStyles();
  return (
    <>
      {links.map((cat) => {
        // Check if cat.id is equal to 4, 8, 12 or 15
        const deliveryIds = [4, 8, 12, 15];
        const insertionPosition = 2;
        const updatedFooterLinksList = deliveryIds.includes(cat.id as number)
          ? cat.footerLinksList
            ? [
                ...cat.footerLinksList.slice(0, insertionPosition),
                {
                  id: 'newId',
                  name: 'Doprava a platba',
                  link: '/DeliveryAndPayment',
                },
                ...cat.footerLinksList.slice(insertionPosition),
              ]
            : []
          : cat.footerLinksList || [];

        return (
          <Grid key={cat.id} item xs={12} md={4}>
            <EText variant="body1">
              <b>{cat.description}</b>
            </EText>
            <br />
            {updatedFooterLinksList.map((l) => {
              return (
                <EText
                  key={l.id}
                  variant="body1"
                  onClick={() => (window.location.href = l.link || '')}
                  className={classes.link}
                >
                  {l.name}
                </EText>
              );
            })}
            <br />
            <br />
          </Grid>
        );
      })}{' '}
    </>
  );
};

export default FooterLinks;
