import React, { useState } from 'react';
import { GoogleApiWrapper, IProvidedProps, Map, Marker } from 'google-maps-react';
import {
  Button,
  Dialog,
  DialogContent,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { reducers } from '../Utils/Reducers';
import useWindowDimensions from '../Hooks/GetWindowDimenstions';
import { Close } from '@material-ui/icons';
import LocationSearchInput from './PlacesSearch';
import { PickupPlace } from '@magistrmartin/eshop-frontend-shared';

interface IProps extends IProvidedProps {
  places: PickupPlace[];
  unavailablePlaceIds?: number[];
  onSelect?: (place: PickupPlace) => void;
  renderOpener: (open: () => void) => JSX.Element;
  onClose?: () => void;
}

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyDDGAi1G0AAiF6_LFvlFS6ZxxkWmaoyrGU',
  language: 'cs-CZ',
}))(({ google, loaded, places, onSelect, renderOpener, onClose, unavailablePlaceIds }: IProps) => {
  const [open, setOpen] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState<{ lat: number; lng: number } | undefined>(undefined);
  const [zoom, setZoom] = useState(10);

  const [selectedPlace, setSelectedPlace] = useState<PickupPlace | undefined>(undefined);
  const { height, width } = useWindowDimensions();

  return (
    <>
      {renderOpener(() => {
        setOpen(true);
        setZoom(10);
      })}
      <Dialog open={open} maxWidth="xl" fullWidth onClose={() => setOpen(false)}>
        <DialogContent style={{ height: '95vh', padding: 0 }}>
          <IconButton
            style={{ position: 'absolute', top: 10, right: 10, zIndex: 999 }}
            onClick={() => {
              setOpen(false);
              onClose && onClose();
            }}
          >
            <Close />
          </IconButton>
          <Drawer
            PaperProps={{
              style: {
                position: 'absolute',
              },
            }}
            variant="persistent"
            anchor={height > width ? 'bottom' : 'left'}
            open={selectedPlace !== undefined}
            onClose={() => setSelectedPlace(undefined)}
          >
            {selectedPlace && (
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'column',
                  padding: 12,
                  maxHeight: height > width ? '40vh' : undefined,
                  height: '100%',
                }}
              >
                <Close
                  style={{
                    position: 'absolute',
                    right: 16,
                    top: 16,
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedPlace(undefined)}
                />
                <Typography variant="h6" style={{ width: '100%' }}>
                  {selectedPlace.name}
                </Typography>
                <br />
                <Typography style={{ width: '100%' }}>
                  {selectedPlace.street} {selectedPlace.houseNum}, {selectedPlace.zip} {selectedPlace.city}
                </Typography>
                <br />
                <Typography variant="h6" style={{ width: '100%' }}>
                  Otevírací doba vybrané lékárny
                </Typography>
                <Table size="small">
                  <TableBody>
                    {selectedPlace.nextTenOpenningDays.map((oh) => (
                      <TableRow key={oh.id}>
                        <TableCell
                          style={{
                            fontWeight: oh.id === 0 ? 'bold' : 'normal',
                          }}
                        >
                          {(oh?.dayName || '').split('\t')[0]}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: oh.id === 0 ? 'bold' : 'normal',
                          }}
                        >
                          {(oh?.dayName || '\t').split('\t')[1]}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: oh.id === 0 ? 'bold' : 'normal',
                          }}
                        >
                          {oh.value || 'Zavřeno'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
                <Typography variant="caption" align="justify">
                  Informace poskytla uvedená lékárna. <br />
                  Nezodpovídáme za aktualitu dat, ale snažíme se je pravidelně kontrolovat.
                  <br />
                  Děkujeme za pochopení.
                </Typography>
                {(unavailablePlaceIds || []).includes(selectedPlace.id || -1) && (
                  <>
                    <br />
                    <Typography variant="caption" align="justify" style={{ color: '#ff0000' }}>
                      Některé objednané produkty nejsou v této lékárně dostupné.
                    </Typography>
                  </>
                )}
                <div style={{ flexGrow: 1 }} />
                {onSelect && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpen(false);
                      onSelect(selectedPlace);
                      setSelectedPlace(undefined);
                    }}
                    color="primary"
                    fullWidth
                  >
                    Vybrat lékárnu
                  </Button>
                )}
              </div>
            )}
          </Drawer>
          {loaded && (
            <LocationSearchInput
              onSelect={(location) => {
                setSearchedLocation(location);
                setZoom(16);
              }}
            />
          )}
          {places.length > 0 && (
            <Map
              google={google}
              initialCenter={{
                lat: places.map((p: PickupPlace) => p.latitude).reduce(reducers.sum) / places.length,
                lng: places.map((p: PickupPlace) => p.longitude).reduce(reducers.sum) / places.length,
              }}
              center={searchedLocation}
              zoom={zoom}
              fullscreenControl={false}
            >
              {places.map((p: PickupPlace) => (
                <Marker
                  onClick={() => setSelectedPlace(p)}
                  key={p.id}
                  position={{ lat: p.latitude, lng: p.longitude }}
                  icon={{
                    url: (unavailablePlaceIds || []).includes(p.id || -1)
                      ? '/img/map-pin-grayscale.webp'
                      : '/img/map-pin.webp',
                    anchor: new google.maps.Point(20, 52),
                    scaledSize: new google.maps.Size(40, 52),
                  }}
                />
              ))}
              {searchedLocation && (
                <Marker
                  position={{
                    lat: searchedLocation.lat,
                    lng: searchedLocation.lng,
                  }}
                  onClick={() => setSearchedLocation(undefined)}
                />
              )}
            </Map>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
});
