import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import EText from './EText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import IHeurekaReview from '../Types/heurekaReview';

interface IProps {
  heurekaReviews: IHeurekaReview[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heurekaLinkContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      margin: '10px 30px',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translate(8px, 0px)',
      },
    },
  })
);

const getRecommendsPercentage = (heurekaReviews: IHeurekaReview[]) => {
  let recommendsSum = 0;
  heurekaReviews.forEach((review) => review.recommends && recommendsSum++);
  return Math.floor((recommendsSum / heurekaReviews.length) * 100);
};

const HeurekaReviewsSummary = ({ heurekaReviews }: IProps) => {
  const classes = useStyles();

  return (
    <div>
      <img src="/img/heurekaLogo.webp" alt="Heureka logo" />
      <EText variant="h6">Ověřeno našimi zákazníky</EText>
      <EText variant="body1">
        {getRecommendsPercentage(heurekaReviews)} % zákazníků doporučuje náš obchod (z posledních{' '}
        {heurekaReviews.length} recenzí). Prohlédněte si vybraná hodnocení našich zákazníků.
      </EText>
      <a
        href="https://obchody.heureka.cz/magistrmarek-cz/recenze/"
        target="_blank"
        rel="noreferrer"
        className={classes.heurekaLinkContainer}
      >
        <FontAwesomeIcon icon={faLongArrowAltRight} />
        <EText variant="body2">Další hodnocení na Heureka.cz</EText>
      </a>
    </div>
  );
};

export default HeurekaReviewsSummary;
