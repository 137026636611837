import React, { useEffect, useState } from 'react';
import { Container, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useCategories } from '../../Hooks/Categories';
import { findCategory } from './Offer';
import { ITreeCategory } from '../../Types/base';
import { useProduct, useProductsPaginated } from '../../Hooks/Products';
import TopProductCard from '../../Components/TopProductCard';
import EText from '../../Components/EText';
import TopSoldProdCard from '../../Components/TopSoldProdCard';
import SortSelect, { SortBy } from '../../Components/SortSelect';
import HeadMap from '../../Components/HeadMap';
import CategoriesSuggestion from './CategoriesSuggestion';
import AgeVerification from '../../Components/AgeVerification';
import { useHistory } from 'react-router-dom';
import Filters, { initialFilterState } from '../../Components/Filters';
import { OfferParamsState, offerFields } from './PaginatedOffer';
interface IProps {
  categoryId: string;
  setBannerToShow: (bannerToShow: string | undefined) => void;
  productsCount: number | undefined;
  offerParamsState: OfferParamsState;
  setOfferParamsState: (offerParamsState: OfferParamsState) => void;
}

const categoryRequiringAgeVerification = (categoryId: string) => {
  const categoriesThatRequireAgeVerification = [12]; // TODO: make this dynamic from administration (category property?)
  return categoriesThatRequireAgeVerification.find((c) => categoryId.startsWith(c.toString())) !== undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFlex: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginBottom: '15px',
    },
    topLayout: {
      display: 'flex',
      gap: '15px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    sortSelectLayout: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },

    topSoldProducts: {
      maxWidth: '50%',
    },

    verticalSpace: {
      marginBottom: '20px',
    },
  })
);

const CategoryOffer = ({
  categoryId,
  setBannerToShow,
  productsCount,
  offerParamsState,
  setOfferParamsState,
}: IProps) => {
  console.log('Cat render!');
  const classes = useStyles();
  const history = useHistory();

  const [category, setCategory] = useState<ITreeCategory | undefined>(undefined);
  const [ageConfirmed, setAgeConfirmed] = useState(
    JSON.parse(sessionStorage.getItem('ageVerified') || 'false') as boolean
  );
  const changeAgeConfirmed = (newVal: boolean) => {
    sessionStorage.setItem('ageVerified', JSON.stringify(newVal));
    setAgeConfirmed(newVal);
  };

  const { data: categoriesData } = useCategories();

  useEffect(() => {
    if (categoriesData) {
      const cat = findCategory(categoriesData, categoryId);
      setCategory(cat);
      setBannerToShow(cat && cat.banner && cat.banner.length ? cat.banner : undefined);
    }
  }, [categoriesData, categoryId, setBannerToShow]);

  const { data: topProduct } = useProduct(category?.topProductId);

  const { data: topProductsData } = useProductsPaginated('category', categoryId, 0, 3, offerFields, {
    filter: undefined,
    sortBy: SortBy.RELEVANCE,
  });

  return (
    <Container maxWidth="lg" className={classes.rootFlex}>
      {category && (
        <>
          <HeadMap currentCategory={category} />
          <EText variant="h4">
            {category.description}
            {productsCount ? ` (${productsCount})` : ' (...)'}
          </EText>

          {category && (
            <div>
              <div className={classes.verticalSpace}>
                <CategoriesSuggestion category={category} />
              </div>
              <Filters
                filterState={offerParamsState.filter ?? initialFilterState}
                setFilterState={(newFilterState) =>
                  setOfferParamsState({ ...offerParamsState, filter: newFilterState })
                }
                categoryId={category.id}
              />
            </div>
          )}
        </>
      )}
      <>
        {!ageConfirmed && categoryRequiringAgeVerification(categoryId) && (
          <AgeVerification // TODO: age verification not working, if you search for example "kondom", products will be showed (it is working just when entering category, not searching etc.)
            onAgeConfirm={() => changeAgeConfirmed(true)}
            onAgeReject={() => {
              changeAgeConfirmed(false);
              history.push('/');
            }}
          />
        )}
        <div className={classes.topLayout}>
          <TopProductCard key={topProduct?.id} product={topProduct} />
          <div className={classes.topSoldProducts}>
            <EText variant="h6">Nejprodávanější produkty</EText>

            <TopSoldProdCard product={topProductsData?.content[0]} />
            <TopSoldProdCard product={topProductsData?.content[1]} />
            <TopSoldProdCard product={topProductsData?.content[2]} />
          </div>
        </div>

        <div className={classes.sortSelectLayout}>
          <SortSelect
            sortState={offerParamsState.sortBy}
            setSortState={(newSortBy) => setOfferParamsState({ ...offerParamsState, sortBy: newSortBy })}
          />
        </div>
      </>
    </Container>
  );
};

export default CategoryOffer;
