import React from 'react';
import { FilterType } from './Offer';
import EText from '../../Components/EText';
import SortSelect from '../../Components/SortSelect';
import { OfferParamsState } from './PaginatedOffer';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IProps {
  filterType: FilterType;
  id: string;
  offerParamsState: OfferParamsState;
  setOfferParamsState: (offerParamsState: OfferParamsState) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '40px',
    },
  })
);

const CriteriaOffer = ({ filterType, id, offerParamsState, setOfferParamsState }: IProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerFlex}>
        <EText variant="h6">
          {filterType === FilterType.SUPPLIER
            ? 'Dodavatel: '
            : filterType === FilterType.BRAND
            ? 'Značka: '
            : filterType === FilterType.PRODUCT_LINE
            ? 'Produktová řada: '
            : ''}
          {id}
        </EText>
        <SortSelect
          sortState={offerParamsState.sortBy}
          setSortState={(newSortBy) => setOfferParamsState({ ...offerParamsState, sortBy: newSortBy })}
        />
      </div>
    </>
  );
};

export default CriteriaOffer;
