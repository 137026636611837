import React, { useState } from 'react';
import {
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Hidden,
  Button,
  IconButton,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
// import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBar: {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          borderRadius: 8,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 168px)',
      },
      [theme.breakpoints.up('md')]: {
        width: 'calc(100vw - 750px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 532,
      },
    },
  })
);

interface IProps {
  label: string;
  search: (value: string) => void;
}

export default function SearchBar({ label, search }: IProps) {
  const [val, setVal] = useState('');
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.searchBar}>
      {/* <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel> */}
      <OutlinedInput
        color="primary"
        labelWidth={0}
        endAdornment={
          <>
            <Hidden xsDown>
              <InputAdornment position="end">
                <Button color="primary" onClick={() => search(val)}>
                  Vyhledat
                </Button>
              </InputAdornment>
            </Hidden>
            <Hidden smUp>
              <InputAdornment position="end">
                <IconButton aria-label="search" onClick={() => search(val)}>
                  <Search />
                </IconButton>
              </InputAdornment>
            </Hidden>
          </>
        }
        value={val}
        onChange={(e) => setVal(e.target.value)}
        placeholder={label}
        onBlur={(e) => search(val)}
        // @ts-ignore
        onKeyPress={(e) => e.key === 'Enter' && search(val)}
      />
    </FormControl>
  );
}
