import React, { useEffect, useState } from 'react';
import ETextFiled from './ETextField';
import { formatters } from '../Utils/InputFormatters';
import { getRegexValidator } from '../Utils/Validators';
import { statisticsService } from '../Utils/ApiService';
import { Autorenew, CheckCircle, Error } from '@material-ui/icons';
import { Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import EText from './EText';

interface IProps {
  label?: string;
  value?: string;
  onChange?: (val: string) => void;
  onValidChange?: (valid: boolean) => void;
  required?: boolean;
}

const useStyles = makeStyles((theme) => ({
  rotateIcon: {
    animation: '$spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export default function FoxentryEmailInput({ onChange, value, required, label, onValidChange }: IProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [foxentryStatus, setFoxentryStatus] = React.useState<
    'none' | 'inProgress' | 'valid' | 'invalid' | 'corrected' | 'error'
  >('none');
  const [foxentrySuggestions, setFoxentrySuggestions] = React.useState<string[]>([]);
  const [lastValidatedValue, setLastValidatedValue] = React.useState<string>('');
  const [visualValidationStarted, setVisualValidationStarted] = React.useState<boolean>(false);
  const [innerValue, setInnerValue] = useState(value || '');

  const basicValidator = getRegexValidator(RegExp(`^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$`));
  const isValid =
    (!required || (value?.length || 0) > 0) &&
    basicValidator(value || '') &&
    (foxentryStatus === 'valid' || foxentryStatus === 'corrected' || foxentryStatus === 'inProgress');

  useEffect(() => {
    onValidChange && onValidChange(foxentryStatus !== 'inProgress' && isValid);
  }, [foxentryStatus, onValidChange, isValid]);

  useEffect(() => {
    setInnerValue(value || '');
    foxentryValidate();
    // eslint-disable-next-line
  }, [value]);

  const foxentryValidate = () => {
    if (!value) return;
    if (foxentryStatus === 'inProgress' || lastValidatedValue === value || !basicValidator(value)) return;
    setFoxentryStatus('inProgress');
    setLastValidatedValue(value);
    statisticsService.get(
      '/foxentry/validateEmail',
      { email: value },
      {
        success: (data) => {
          if (data.correction) {
            onChange && onChange(data.correction);
            setFoxentryStatus('corrected');
          } else {
            setFoxentryStatus(data.isValid ? 'valid' : 'invalid');
          }
          setFoxentrySuggestions(data.suggestions);
        },
        error: () => setFoxentryStatus('error'),
      }
    );
  };

  return (
    <>
      <ETextFiled
        label={label}
        value={innerValue}
        onChange={setInnerValue}
        onBlur={() => {
          onChange && onChange(innerValue);
          setVisualValidationStarted(true);
        }}
        required={required}
        validator={() => !visualValidationStarted || isValid}
        invalidText={
          foxentryStatus === 'error'
            ? 'Validace emailu skončila s chybou'
            : foxentryStatus === 'invalid'
              ? 'Zadali jste neplatný email'
              : 'Email nemá správný formát'
        }
        formatter={formatters.StripFormatter}
        icon={
          foxentryStatus === 'inProgress' ? (
            <Tooltip title="Probíhá validace emailu...">
              <Autorenew className={classes.rotateIcon} color="action" />
            </Tooltip>
          ) : foxentryStatus === 'valid' || foxentryStatus === 'corrected' ? (
            <Tooltip title="Email byl úspěšně ověřen">
              <CheckCircle color="primary" />
            </Tooltip>
          ) : foxentryStatus === 'invalid' ? (
            <Tooltip title="Zadali jste neplatný email">
              <Error color="error" />
            </Tooltip>
          ) : foxentryStatus === 'error' ? (
            <Tooltip title="Při validaci emailu došlo k chybě, zkuste to prosím později">
              <Error color="error" />
            </Tooltip>
          ) : undefined
        }
      />
      {foxentryStatus === 'corrected' && lastValidatedValue !== value ? (
        <Typography style={{ color: theme.palette.warning.main }} variant="caption">
          Váš email byl automaticky opraven. Zadali jste email: {lastValidatedValue}. Prosím zkontrolujte, že je
          navržená oprava správná.
        </Typography>
      ) : null}
      {(foxentrySuggestions.length && (
        <>
          <br />
          <br />
          <EText variant="caption">Měli jste na mysli?</EText>
          {foxentrySuggestions.map((suggestion, index) => (
            <EText
              key={index}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                onChange && onChange(suggestion);
                setFoxentrySuggestions([]);
                setFoxentryStatus('valid');
                setLastValidatedValue(suggestion);
              }}
            >
              {suggestion}
            </EText>
          ))}
        </>
      )) || <></>}
    </>
  );
}
