import React from 'react';
import Environment from '../Environments';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IProps {
  direction: 'row' | 'column';
  textColor?: string;
}
const useStyles = (props: IProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      outerContainer: {
        display: 'flex',
        flexDirection: props.direction,
        gap: '10px',
      },
      topPanelBtn: {
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        fontSize: '13px',
        cursor: 'pointer',
        color: props.textColor ?? theme.palette.primary.main,
        fontWeight: 'bold',
        padding: 6,
        borderRadius: '8px',
        textWrap: 'nowrap',
      },
      topPaneSecondBtn: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
      },
      topPaneThirdBtn: {
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
      },
      topPaneFourthBtn: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
      },
      topPanelTextBtn: {},
    })
  );
const HeaderButtonsStack = ({ direction, textColor }: IProps) => {
  const classes = useStyles({ direction, textColor })();
  return (
    <>
      <div className={classes.outerContainer}>
        {!Environment.hideHeaderIcons && (
          <>
            <div
              className={`${classes.topPanelBtn} ${classes.topPaneSecondBtn}`}
              onClick={() => (window.location.href = 'https://erecept.magistrmartin.cz/')}
            >
              Rezervace eReceptu
            </div>
            <div
              className={`${classes.topPanelBtn} ${classes.topPaneThirdBtn}`}
              onClick={() => (window.location.href = 'https://lekarnickyproskoly.cz/')}
            >
              LekarnickyProSkoly.cz
            </div>
            <div
              className={`${classes.topPanelBtn} ${classes.topPaneFourthBtn}`}
              onClick={() => (window.location.href = ' https://rodinnedodavky.lekarnaselska.cz/#/')}
            >
              Portál lékaře
            </div>
          </>
        )}

        <div className={classes.topPanelBtn} onClick={() => (window.location.href = '/DeliveryAndPayment')}>
          Doprava a platba
        </div>
        <div className={classes.topPanelBtn} onClick={() => (window.location.href = Environment.contactsPage)}>
          Kontakty
        </div>
      </div>
    </>
  );
};

export default HeaderButtonsStack;
