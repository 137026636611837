import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import ProductPreviewCard from '../Components/ProductPreviewCard';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import EText from '../Components/EText';
import { Help } from '@material-ui/icons';
import { IForm } from '../Types/base';

interface IProps {
  productId: number;
}

export default function Substances({ productId }: IProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedSubs, setSelectedSubs] = useState<string[]>([]);
  const [currentProduct, setCurrentProduct] = useState<IProduct | undefined>(undefined);
  const [form, setForm] = useState<IForm | undefined>();
  const [formOnly, setFormOnly] = useState(true);
  const layout = useLayout();

  const loadProducts = (subs: string[]) => {
    catalogService.get(
      'products/noauth/filter',
      { filterType: 'activeSubstances', filterValue: subs.join(';') },
      {
        success: setProducts,
        error: () => layout.error('Při načítání produktů došlo k chybě.'),
      }
    );
  };

  const loadForm = (categories: string[], formId: number) =>
    catalogService.get(
      '/settings/noauth/simpleForms',
      {},
      {
        success: (forms: IForm[]) =>
          setForm(
            forms.filter(
              (f) => categories[0].startsWith(f.category) && f.bindUnits.includes(parseInt(formId.toString()))
            )[0]
          ),
        error: console.log,
      }
    );

  useEffect(() => {
    setProducts([]);
    setCurrentProduct(undefined);
    catalogService.get(
      '/products/noauth',
      {
        id: productId,
        mapped: true,
      },
      {
        success: (prd: IProduct) => {
          setCurrentProduct(prd);
          setSelectedSubs(prd.activeSubstances?.map((a) => a) || []);
          loadProducts(prd.activeSubstances?.map((a) => a) || []);
          loadForm(prd.categories || [], prd.formId || 0);
        },
        error: () => layout.error('Při načítání produktu došlo k chybě.'),
      }
    );
    // eslint-disable-next-line
  }, [productId]);

  const filteredProds = products.filter(
    (prd) => form === undefined || !formOnly || form.bindUnits.includes(parseInt(prd.formId?.toString() || '0'))
  );

  const sameSubstProds = filteredProds.filter((prd) => prd.activeSubstances?.length === selectedSubs.length);
  const diffSubstProds = filteredProds.filter((prd) => prd.activeSubstances?.length !== selectedSubs.length);

  return (
    <Container maxWidth="lg">
      <EText variant="h4">Přehled produktů podle účinných látek</EText>
      <br />
      <EText variant="h6" inline>
        Účinné látky v produktu {currentProduct?.title}{' '}
        <span style={{ fontSize: 'small' }}>{currentProduct?.subtitle}</span>
      </EText>
      {//@ts-ignore
      currentProduct?.activeSubstances?.map((as) => (
        <React.Fragment key={as}>
          <Button
            variant="contained"
            color={selectedSubs.includes(as) ? 'primary' : 'default'}
            onClick={() => {
              const newSubs = selectedSubs.includes(as)
                ? selectedSubs.filter((ss) => ss !== as)
                : [...selectedSubs, as];
              if (newSubs.length > 0) {
                loadProducts(newSubs);
                setSelectedSubs(newSubs);
              }
            }}
          >
            {as}
          </Button>
          &nbsp;&nbsp;
        </React.Fragment>
      ))}
      {(currentProduct?.activeSubstances?.length || 0) > 1 && (
        <Tooltip title="Kliknutím na látku je možné vybrat libovolnou kombinaci účiných látek, které má produkt obsahovat.">
          <Help color="primary" />
        </Tooltip>
      )}
      <br />
      <br />
      {form && (
        <>
          <FormControlLabel
            control={<Checkbox color="primary" checked={formOnly} />}
            label={`Zobrazit pouze ${form.name}`}
            color="primary"
            onClick={() => setFormOnly(!formOnly)}
          />
        </>
      )}
      {sameSubstProds.length > 0 && (
        <>
          <br />
          <br />
          <EText variant="h6">Produkty s vybranou kombinací účinných/aktivních látek</EText>
          <br />
          <Grid container>
            {sameSubstProds.map((prd) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={prd.id}>
                <ProductPreviewCard product={prd} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {diffSubstProds.length > 0 && (
        <>
          <br />
          <br />
          <EText variant="h6">
            DALŠÍ ALTERNATIVY - níže uvedené produkty obsahují kromě zvolené kombinace účinných/aktivních látek i další
            látky navíc
          </EText>
          <br />
          <Grid container>
            {diffSubstProds.map((prd) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={prd.id}>
                <ProductPreviewCard product={prd} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <br />
    </Container>
  );
}
